import { useQuery } from '@tanstack/react-query';
import { queries } from '../../../queries';
import { OrganizationType } from '@grain/core-types';
import { useEffect, useState } from 'react';
import { AdminConsoleApiWallet } from '@grain/admin-console-api-types';

export const useWallets = (partnerId: string, organizationType: OrganizationType) => {
  const [wallets, setWallets] = useState<AdminConsoleApiWallet[]>();
  const { data, isLoading, isError } = useQuery(queries.partners.wallets.list(partnerId, organizationType));

  useEffect(() => {
    if (data) {
      setWallets(data);
    }
  }, [data]);

  return {
    wallets,
    isLoadingWallets: isLoading,
    isErrorLoadingWallets: isError
  };
};
