import React, { ReactElement } from 'react';
import './BaseDropdown.scss';
import { cn } from '../utils';
import BaseField from './BaseField';
import { MenuItem, Select } from '@mui/material';
import BaseInput, { BaseInputProps } from './BaseInput';
import { CloseCircle } from 'iconsax-react';
import BaseIcon from '../icons/BaseIcon';

interface IProps extends BaseDropdownProps {
  clearableAction?: (() => void) | null;
  displayEmpty?: boolean;
}

export default function BaseDropdown(props: IProps) {
  const {
    name,
    labelText,
    errorMessage,
    containerClassName,
    className,
    required,
    options,
    hideErrorText,
    shadow,
    sideLabel,
    renderValue,
    clearableAction,
    multiple,
    displayEmpty,
    ...inputProps
  } = props;

  return (
    <div className={cn('base-dropdown-container', containerClassName)}>
      <BaseField
        name={name}
        labelText={labelText}
        errorMessage={errorMessage}
        required={required}
        shadow={shadow}
        hideErrorText={hideErrorText}
        sideLabel={sideLabel}
      >
        <Select
          displayEmpty={displayEmpty}
          multiple={multiple}
          IconComponent={
            clearableAction
              ? () => (
                  <div className="mx-3" onClick={clearableAction}>
                    <BaseIcon size={16} appearance="grey">
                      <CloseCircle />
                    </BaseIcon>
                  </div>
                )
              : undefined
          }
          variant="outlined"
          name={name}
          renderValue={renderValue}
          required={required}
          label={labelText}
          input={<BaseInput hideErrorText errorMessage={errorMessage} {...inputProps} />}
          className={cn('field-element base-dropdown', className)}
        >
          {options.map((option) => (
            <MenuItem disabled={option.disabled} key={option.value} value={option.value}>
              {option.text}
            </MenuItem>
          ))}
        </Select>
      </BaseField>
    </div>
  );
}

export interface BaseDropdownProps extends BaseInputProps {
  containerClassName?: string;
  options: BaseDropdownOption[];
  renderValue?: (value: string | string[]) => ReactElement;
  multiple?: boolean;
}

export type BaseDropdownOption = {
  text: string;
  value: string;
  disabled?: boolean;
};
