import React, { useCallback, useState } from 'react';
import { cn } from '@grain/web-components';
import SectionCard from '@grain/web-components/layout/SectionCard';
import colors from '@grain/web-components/styles/colors.scss';
import { Data } from 'iconsax-react';
import './MapTransactionsData.scss';
import { AlertsService, AnalyticsService } from '@grain/web-utils';
import BaseDataMapper, { MAPPING_TIMEOUT } from '@grain/web-components/mapping/BaseDataMapper';
import { ALL_MAPPING_FIELD_GROUPS, PARTNER_FIELDS_MAPPING } from './mapping-fields';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import { FileMappingRequest } from '@grain/api-utils';
import { MappingTransactionType } from '@grain/core-types';
import PartnersService from '../../../services/PartnersService';

const MAPPING_FORM_ID = 'transactions-mapper';

export default function MapTransactionsData(props: MapTransactionsDataProps) {
  const [loading, setLoading] = useState<boolean>(true);
  const { transactionsFile, onParseFailed, onMappingComplete, transactionsFileUrl, partnerId } = props;

  const reportParseError = useCallback(
    (e: Error) => {
      AnalyticsService.getInstance().logEvent('Illegal Transaction File Format Uploaded', {
        extension: transactionsFile.name?.split('.').pop()
      });
      AlertsService.getInstance().captureException(e);
      onParseFailed(e);
    },
    [transactionsFile, onParseFailed]
  );

  const reportParseTimeout = useCallback(() => {
    AnalyticsService.getInstance().logEvent('Parse Timeout', {
      timeoutMs: MAPPING_TIMEOUT
    });
    AlertsService.getInstance().captureMessage(`Timeout after trying to parse the file for ${MAPPING_TIMEOUT / 1000} seconds.`, 'warning');
    onParseFailed(new Error('Timeout after attempting to parse file'));
  }, [onParseFailed]);

  const doneButton = <CTAButton text="Done" type="submit" size="small" form={MAPPING_FORM_ID} />;

  const mapFile = async (data: FileMappingRequest<MappingTransactionType>) => {
    try {
      setLoading(true);
      await PartnersService.getInstance().mapTransactionsFile(partnerId, data);
      onMappingComplete();
    } finally {
      setLoading(false);
    }
  };

  const fieldGroups = [...ALL_MAPPING_FIELD_GROUPS, PARTNER_FIELDS_MAPPING];

  return (
    <SectionCard
      title="Connect your data"
      className={cn('map-transactions-data-container', { loading })}
      icon={<Data />}
      iconBackgroundColor={colors.secondaryOrange}
      headerRightComponent={doneButton}
    >
      <BaseDataMapper<MappingTransactionType>
        formId={MAPPING_FORM_ID}
        file={transactionsFile}
        onParseFailed={reportParseError}
        onParseTimeout={reportParseTimeout}
        fieldGroups={fieldGroups}
        mapFile={mapFile}
        fileUrl={transactionsFileUrl}
        onMapperLoading={setLoading}
        loading={loading}
        allowAdvancedSettings
      />
    </SectionCard>
  );
}

type MapTransactionsDataProps = {
  transactionsFileUrl: string;
  transactionsFile: File;
  onParseFailed: (e: Error) => void;
  onMappingComplete: () => void;
  partnerId: string;
};
