declare global {
  interface Window {
    grainEnvironment: any;
  }
}

window.grainEnvironment = window.grainEnvironment || {};

interface Environment {
  adminConsoleApiUrl: string;
  isProduction: boolean;
  sentryDSN: string;
}

const env: Environment = {
  adminConsoleApiUrl: process.env.REACT_APP_ADMIN_CONSOLE_API_URL || window.grainEnvironment.ADMIN_CONSOLE_API_URL,
  isProduction: process.env.NODE_ENV === 'production' || window.grainEnvironment.NODE_ENV === 'production',
  sentryDSN: 'https://7655529851067a4fbb05d6629fd62417@o1278787.ingest.us.sentry.io/4507678893211648'
};

export default env;
