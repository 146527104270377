import React, { ReactElement, useEffect, useRef, useState } from 'react';
import DetailsPage from 'components/layout/DetailsPage';
import { StretchCard } from '@grain/web-components/layout/Card';
import { CurrenciesTable, DialogState } from './CurrenciesTable';
import { CurrenciesFilters } from './CurrenciesFilters';
import { GrainCurrencyPair } from '@grain/core-types';
import { ButtonRow } from '@grain/web-components/button-row/ButtonRow';
import { IconicButton } from '@grain/web-components/iconic-button/IconicButton';
import { Add, Chart21, DocumentUpload } from 'iconsax-react';
import './CurrenciesPage.scss';
import UpdateForwardPointsDialog from 'components/forwards/UpdateForwardPointsDialog';
import { useGrainConfig, useRefreshGrainConfig } from 'hooks';
import AddEditCurrencyPairDialog from './AddEditCurrencyPairDialog';
import EditGrainConfigDialog from 'components/currencies/EditGrainConfigDialog';
import { ALL_CURRENCIES_OPTION } from '@grain/web-components/currency-filter-select/CurrencyFilterSelect';
import { useSearchParams } from 'react-router-dom';
import { getCurrencyPairQueryParams } from './currencies-utils';
import GrainSettingsService from '../../services/GrainSettingsService';

export const GRAIN_CURRENCY_PAIR_FILTERS_DEFAULT_SELECTION: Partial<GrainCurrencyPair> = {
  fromCurrency: ALL_CURRENCIES_OPTION.value,
  toCurrency: ALL_CURRENCIES_OPTION.value
};

export function CurrenciesPage(): ReactElement {
  const [searchParams, setSearchParams] = useSearchParams();

  const currenciesContentRef = useRef<HTMLDivElement>(null);
  const { refreshGrainConfig } = useRefreshGrainConfig();
  const [editDialogState, setEditDialogState] = useState<DialogState>({ isRowDialogOpen: false, activeCurrencyPair: null });
  const [enrichingActiveCurrencyPair, setEnrichingActiveCurrencyPair] = useState<boolean>(false);
  const [filters, setFilters] = useState<Partial<GrainCurrencyPair>>({
    fromCurrency: searchParams.get('fromCurrency') || ALL_CURRENCIES_OPTION.value,
    toCurrency: searchParams.get('toCurrency') || ALL_CURRENCIES_OPTION.value
  });
  const { grainConfig } = useGrainConfig();
  useEffect(() => {
    setSearchParams(
      getCurrencyPairQueryParams({
        fromCurrency: filters.fromCurrency,
        toCurrency: filters.toCurrency
      })
    );
  }, [filters.fromCurrency, filters.toCurrency, setSearchParams]);

  useEffect(() => {
    if (!editDialogState.isRowDialogOpen) return;
    const enrichGrainCurrencyPair = async () => {
      try {
        setEnrichingActiveCurrencyPair(true);
        if (!editDialogState.activeCurrencyPair) return;
        const ticker = await GrainSettingsService.getInstance().fetchSymbolTicker(editDialogState.activeCurrencyPair.symbol);
        setEditDialogState(
          (prevState) =>
            ({
              ...prevState,
              activeCurrencyPair: {
                ...prevState.activeCurrencyPair,
                ticker
              }
            }) as DialogState
        );
      } catch (e) {
        // NoOp
      } finally {
        setEnrichingActiveCurrencyPair(false);
      }
    };
    enrichGrainCurrencyPair();
  }, [editDialogState.isRowDialogOpen]);

  return (
    <DetailsPage title="Currencies Settings" subtitle="Manage Grain's settings">
      <div ref={currenciesContentRef} className="currencies-content">
        <div className="currencies-controls">
          <CurrenciesFilters onChange={setFilters} dropdownSelection={filters} />
          <ButtonRow>
            <UpdateForwardPointsDialog
              onUploadSucceed={(_, url: string) => alert(url)}
              triggerComponent={<IconicButton icon={DocumentUpload} text="Forward points" />}
            />
            {grainConfig && (
              <EditGrainConfigDialog
                initialData={grainConfig}
                onCloseAfterSuccess={refreshGrainConfig}
                triggerComponent={<IconicButton icon={Chart21} text="Config Details" />}
              />
            )}
            <AddEditCurrencyPairDialog
              onSubmitCallback={setFilters}
              trigger={<IconicButton icon={Add} text="Add Currency Pair" />}
              title={`Add Currency Pair`}
              mode="add"
              activeCurrencyPair={null}
            />
            <AddEditCurrencyPairDialog
              onSubmitCallback={setFilters}
              title={`Edit Currency Pair`}
              open={editDialogState.isRowDialogOpen}
              activeCurrencyPair={editDialogState.activeCurrencyPair}
              mode="edit"
              onCancel={() => setEditDialogState({ isRowDialogOpen: false, activeCurrencyPair: null })}
              onClose={() => {
                setEditDialogState({ isRowDialogOpen: false, activeCurrencyPair: null });
                currenciesContentRef.current?.scrollIntoView();
              }}
              enrichingActiveCurrencyPair={enrichingActiveCurrencyPair}
            />
          </ButtonRow>
        </div>
        <StretchCard>
          <CurrenciesTable
            {...filters}
            updateEditDialogState={({ isRowDialogOpen, activeCurrencyPair }) => {
              setEditDialogState({ isRowDialogOpen, activeCurrencyPair });
            }}
            onSubmitCallback={() =>
              setFilters({
                fromCurrency: ALL_CURRENCIES_OPTION.value,
                toCurrency: ALL_CURRENCIES_OPTION.value
              })
            }
          />
        </StretchCard>
      </div>
    </DetailsPage>
  );
}
