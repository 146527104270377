import BaseInput from '@grain/web-components/forms/BaseInput';
import React, { ChangeEvent, useState } from 'react';
import { MaxHedgeDurationInDays, oneYearTenors, Tenor, TenorRate, twoYearsTenors } from '@grain/core-types';
import './OfflineMarkupForm.scss';
import CTAButton from '@grain/web-components/buttons/CTAButton';

export default function OfflineMarkupForm(props: OfflineMarkupFormProps) {
  const { tenorRates, updateCurrencyPairTenorMarkup, maxHedgeDurationInDays, currencyPair, onSaveRequest } = props;
  const [newData, setNewData] = useState<TenorRate>(tenorRates);
  const [error, setError] = useState<TenorErrorMap>({} as TenorErrorMap);
  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  const handleSaveClick = async () => {
    if (!canSubmit) {
      return;
    }

    updateCurrencyPairTenorMarkup(newData, currencyPair);
    onSaveRequest?.();
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    setNewData({ ...newData, [name]: value });
    if (Number.isNaN(Number(value))) {
      setError({ ...error, [name]: 'Must be a number' });
      setCanSubmit(false);
    } else if (Number(value) <= 0) {
      setError({ ...error, [name]: 'Must be greater then 0' });
      setCanSubmit(false);
    } else {
      setError({ ...error, [name]: undefined });
      setCanSubmit(true);
    }
  };
  const tenors = maxHedgeDurationInDays === MaxHedgeDurationInDays.OneYear ? oneYearTenors : twoYearsTenors;
  return (
    <form className="offline-markup-form-container">
      <div className="form-title"> Markups </div>
      <div className="offline-markup-form">
        {tenors.map((days) => {
          const rate = newData[days];
          return (
            <BaseInput
              key={days}
              name={days}
              labelText={`${days} Days`}
              value={rate}
              onChange={onChange}
              placeholder="N/A"
              errorMessage={error[days]}
              small
            />
          );
        })}
      </div>

      <CTAButton text="Save" disabled={!canSubmit} size="small" onClick={handleSaveClick} />
    </form>
  );
}

type OfflineMarkupFormProps = {
  updateCurrencyPairTenorMarkup: (tenorRates: TenorRate, currencyPair: string) => void;
  currencyPair: string;
  tenorRates: TenorRate;
  maxHedgeDurationInDays: MaxHedgeDurationInDays;
  onSaveRequest?: () => void;
};

type TenorErrorMap = Record<Tenor, string>;
