import React, { ReactElement, useEffect, useRef, useState } from 'react';
import BaseDialog, { BaseDialogHandle } from './BaseDialog';
import DialogFormStep from './DialogFormStep';

export default function FormDialog({ trigger, open, onSubmit, children, onClose, leftHeader, ...dialogFormStepProps }: FormDialogProps) {
  const [success, setSuccess] = useState<boolean>(false);

  const isControlled = open !== undefined;
  const dialogRef = useRef<BaseDialogHandle>(null);

  const onSubmitClicked = async () => {
    await onSubmit();
    setSuccess(true);
  };

  const onCloseClicked = () => {
    onClose?.(success);
    setSuccess(false);
  };

  useEffect(() => {
    if (success) {
      dialogRef.current?.hideDialog();
    }
  }, [success]);

  return (
    <BaseDialog
      {...(isControlled ? { open } : {})}
      trigger={trigger}
      onClose={onCloseClicked}
      onCancel={onCloseClicked}
      ref={dialogRef}
      leftHeader={leftHeader}
    >
      <DialogFormStep onSubmit={onSubmitClicked} {...dialogFormStepProps}>
        {children}
      </DialogFormStep>
    </BaseDialog>
  );
}

export type FormDialogProps = {
  open?: boolean;
  canSubmit: boolean;
  trigger?: ReactElement;
  title: string;
  validate?: () => string;
  onSubmit: () => Promise<void>;
  onClose?: (success: boolean) => void;
  children: React.ReactNode;
  className?: string;
  submitButton?: ReactElement;
  leftHeader?: string;
};
