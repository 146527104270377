import React from 'react';
import './MessageDialog.scss';
import BaseDialog from '../dialogs/BaseDialog';
import CTAButton from '../buttons/CTAButton';
import { cn } from '../utils';

export default function ErrorDialog(props: ErrorDialogProps) {
  const { className, title, content, onAccept, acceptText = 'OK' } = props;

  const onAcceptClick = () => {
    onAccept();
  };

  return (
    <BaseDialog
      actAsScreen
      contentClassName={cn('message-dialog-content-container', className)}
      onAccept={onAcceptClick}
      onCancel={onAcceptClick}
      acceptButton={<CTAButton text={acceptText} appearance="empty" />}
    >
      <div className="title">{title}</div>
      <div className="content">{content}</div>
    </BaseDialog>
  );
}

interface ErrorDialogProps {
  className?: string;
  title: string;
  content: React.ReactNode;
  acceptText?: string;
  onAccept: () => void;
}
