import { useQuery } from '@tanstack/react-query';
import { queries } from '../queries';

export const useTreasuryWallets = () => {
  const { data, isError, isLoading } = useQuery({
    ...queries.treasury.wallets.list
  });

  return {
    treasuryWallets: data,
    hasFailedLoadingTreasuryWallets: isError,
    isLoadingTreasuryWallets: isLoading
  };
};
