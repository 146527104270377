import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { queries } from 'queries';

export function useGrainCurrencyPairs() {
  const {
    data: grainCurrencyPairs,
    isError: isErrorLoadingGrainCurrencyPairs,
    isLoading: isLoadingGrainCurrencyPairs
  } = useQuery({
    ...queries.settings.grainCurrencyPairs.all(),
    placeholderData: keepPreviousData,
    staleTime: 5_000
  });

  return {
    isLoadingGrainCurrencyPairs,
    isErrorLoadingGrainCurrencyPairs,
    grainCurrencyPairs
  };
}
