import React, { forwardRef, PropsWithChildren, RefAttributes } from 'react';
import * as Select from '@radix-ui/react-select';
import './FilterSelect.scss';
import { cn } from '../utils';
import colors from '../styles/colors.scss';
import * as Label from '@radix-ui/react-label';
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';

export interface FilterSelectOption {
  text: string;
  value: string;
}

export type FilterSelectProps = PropsWithChildren<{
  label?: string;
  onValueChange: (value: string) => void;
  options?: FilterSelectOption[];
  selectValueClassName?: string;
  value?: string;
  outlined?: boolean;
  triggerClassName?: string;
  dataTestId?: string;
}>;

export function FilterSelect(props: FilterSelectProps) {
  const { triggerClassName, label, options, value, outlined, dataTestId } = props;

  return (
    <div className="select-container flex items-center gap-2.5">
      {label && (
        <Label.Root className="text-secondary-grey text-sm leading-none" htmlFor={label}>
          {label}
        </Label.Root>
      )}

      <Select.Root value={value} onValueChange={props.onValueChange}>
        <Select.Trigger
          data-testid={dataTestId}
          aria-label={label}
          className={cn('select-trigger', triggerClassName, { outlined })}
          id={label}
        >
          <div className={cn('select-value truncate text-secondary-grey font-medium text-xs', props.selectValueClassName)}>
            <Select.Value />
          </div>

          <Select.Icon className="select-icon">
            <ArrowDown2 color={colors.darkBlue} size={15} />
          </Select.Icon>
        </Select.Trigger>

        <Select.Portal>
          <Select.Content className="select-content" position="popper">
            <Select.ScrollUpButton className="select-scroll-button">
              <ArrowUp2 size={15} />
            </Select.ScrollUpButton>
            <Select.Viewport className="select-viewport">
              {options
                ? options.map(({ text, value }) => (
                    <SelectFilterItem key={value} data-testid={`select-filter-item-${text}`} value={value}>
                      {text}
                    </SelectFilterItem>
                  ))
                : props.children}
            </Select.Viewport>
            <Select.ScrollDownButton className="select-scroll-button">
              <ArrowDown2 size={15} />
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
    </div>
  );
}

export const SelectFilterItem = forwardRef<HTMLDivElement, Select.SelectItemProps & RefAttributes<HTMLDivElement>>(
  function SelectFilterItem({ children, className, ...props }, forwardedRef) {
    return (
      <Select.Item className={cn('select-item', className)} {...props} ref={forwardedRef}>
        <Select.ItemText>{children}</Select.ItemText>
      </Select.Item>
    );
  }
);
