import { useMutation } from '@tanstack/react-query';
import PartnersService, { WithdrawFundsToGrainParams } from '../services/PartnersService';

export const useWithdrawFundsToGrainTreasury = () => {
  const {
    mutateAsync: withdrawFundsToGrainBeneficiary,
    isError: withdrawalFailed,
    isPending: isWithdrawing,
    isSuccess: withdrawalSucceeded,
    reset: resetWithdrawFundsToGrainMutation,
    error: withdrawalError
  } = useMutation<void, Error, WithdrawFundsToGrainParams>({
    mutationFn: (params) => PartnersService.getInstance().withdrawFundsToGrain(params)
  });

  return {
    withdrawFundsToGrainBeneficiary,
    withdrawalFailed,
    isWithdrawing,
    withdrawalSucceeded,
    resetWithdrawFundsToGrainMutation,
    withdrawalError
  };
};
