import { LiquidityProviderAccountOverride } from './partner';

export enum ForwardStatus {
  Error = 'error', // forwards where not purchased due to unexpected issue, need manual investigation
  Delayed = 'delayed', // forwards where not purchased due to unavailable liquidity provider
  Scheduled = 'scheduled', // forward was created and sent to be booked
  AwaitingFunds = 'awaiting_funds', // conversion created and the FX rate secured.
  TradeSettled = 'trade_settled', // finished state,the bought amount of the conversion has been credited to the relevant balance.
  PendingManual = 'pending_manual' // forwards that cannot be booked by the API and requires manual action
}

export type ForwardQuote = BaseForwardDetails & {
  deposit?: {
    amount: number;
    currency: string;
  };
  externalQuoteId?: string;
  bidPrice?: number;
  forwardPoints?: number; // After remove cc need to make it not optional
};

export type BaseForwardDetails = {
  fromCurrency: string;
  fromCurrencyAmount: number;
  toCurrency: string;
  toCurrencyAmount: number;
  endAt: Date;
  liquidityProvider?: LiquidityProvider;
  price?: number;
  status?: ForwardStatus;
  orderError?: string;
  points?: number;
  spotRate?: number;
};

export type Forward = BaseForwardDetails & {
  id?: string;
  externalId: string;
  startAt: Date;
  settlementAt: Date;
};

export type ForexResponse = {
  symbol: string;
  direction: ForexDirection;
};

export enum ForexDirection {
  Buy = 1,
  Sell = 2
}

export type ForwardQuoteRequest<DateFormat = Date> = {
  fromCurrency: string;
  toCurrency: string;
  amount: number;
  direction: QuoteFixedSide;
  endDate?: DateFormat;
};

export enum QuoteFixedSide {
  ToCurrency = 'buy',
  FromCurrency = 'sell'
}

export enum ForwardBookers {
  BookForHedge = 'book-for-hedge', // forward booked by "hedge created" event
  Manually = 'manually', // forward booked manually by an admin user
  DelayedJob = 'delayed-job', // forward booked by "book delayed" job
  BookForBucket = 'book-for-bucket' // forward booked after passing the bucket threshold
}

export enum LiquidityProvider {
  HAPI = 'HAPI',
  JP = 'JP',
  SWISS = 'SWISS'
}

export type BookForwardMetaData = {
  liquidityProviderOverrideAccount?: LiquidityProviderAccountOverride;
  bookedBy: ForwardBookers;
};

export type Swap = {
  endAtNearLeg: Date;
  endAtFarLeg: Date;
  toCurrencyNearLeg: string;
  toCurrencyFarLeg: string;
  toCurrencyAmountNearLeg: number;
  toCurrencyAmountFarLeg: number;
  fromCurrencyNearLeg: string;
  fromCurrencyFarLeg: string;
  fromCurrencyAmountNearLeg: number;
  fromCurrencyAmountFarLeg: number;
  priceNearLeg: number;
  priceFarLeg: number;
  orderError: string;
  pointsNearLeg: number;
  pointsFarLeg: number;
  spotRate: number;
  liquidityProvider: LiquidityProvider;
};
