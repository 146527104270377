import React, { ReactElement, useState } from 'react';
import './DetailsCard.scss';
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';
import CTAButton from '../buttons/CTAButton';
import useWindowDimensions, { BreakpointType } from '../layout/use-window-dimensions';
import DetailsCardValues, { DetailsCardValue } from './DetailsCardValues';

import { cn } from '../utils';

const DEFAULT_VISIBLE_DETAILS_AMOUNT = 5;

export default function DetailsCard({ header, values, buttons, visibleDetailsAmount = DEFAULT_VISIBLE_DETAILS_AMOUNT }: DetailsCardProps) {
  const [showMore, setShowMore] = useState<boolean>(true);

  const { breakpointType } = useWindowDimensions();
  visibleDetailsAmount = breakpointType === BreakpointType.Tablet ? Math.trunc(visibleDetailsAmount / 2) * 2 : visibleDetailsAmount;

  const nonNullValues = values.filter(({ content }) => content != null && content !== '');

  const toggleShowMore = () => setShowMore(!showMore);
  const extraParams = showMore ? { trailingIcon: <ArrowDown2 />, text: 'Show more' } : { trailingIcon: <ArrowUp2 />, text: 'Show less' };
  const showMoreLessButton = <CTAButton appearance="light-blue-bg" onClick={toggleShowMore} size="small" {...extraParams} />;

  const visibleDetails = showMore ? nonNullValues.slice(0, visibleDetailsAmount) : nonNullValues;

  return (
    <div className="details-card-container">
      {header && <div className="header">{header}</div>}
      <div className="inner-details-card-container">
        <DetailsCardValues values={visibleDetails} />
        {nonNullValues.length > visibleDetailsAmount ? <div className="show-more-less-button">{showMoreLessButton}</div> : null}
      </div>

      {buttons && (
        <div
          className={cn('button-footer', {
            multiple: buttons.length > 1
          })}
        >
          {buttons.map((button, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <React.Fragment key={`details-card-button-${index}`}>
              {button}
              {index < buttons.length - 1 && <div className="divider" />}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
}

export type DetailsCardProps = {
  header?: React.ReactNode;
  values: DetailsCardValue[];
  buttons?: ReactElement[];
  visibleDetailsAmount?: number;
};
