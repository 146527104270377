import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import PartnersService, { GetCurrencyPairsParams } from '../services/PartnersService';
import { OrganizationType } from '@grain/core-types';

export const partnersQueries = createQueryKeyStore({
  wallets: {
    list: (partnerId: string, organizationType: OrganizationType) => {
      return {
        queryKey: ['partnerId', partnerId, 'organizationType', organizationType],
        queryFn: () => PartnersService.getInstance().getWallets(partnerId, organizationType)
      };
    }
  },
  currencyPairs: {
    list: (params: GetCurrencyPairsParams) => {
      return {
        queryKey: ['partnerId', params.partnerId, 'searchTerm', params.searchTerm],
        queryFn: () => PartnersService.getInstance().getCurrencyPairs(params)
      };
    }
  },
  users: {
    list: (partnerId: string) => {
      return {
        queryKey: [partnerId],
        queryFn: () => PartnersService.getInstance().getPartnerUsers(partnerId)
      };
    }
  }
});
