import React, { useCallback } from 'react';
import { AdminConsoleApiCountryConfig, CountryRating } from '@grain/admin-console-api-types';
import BaseTable, { BaseTableColumnDef } from '@grain/web-components/table/BaseTable';
import countries from 'i18n-iso-countries';
import './CountryRestrictionsTable.scss';
import SelectOptionDialog from '@grain/web-components/dialogs/SelectOptionDialog';
import CountryRestrictionStatusBadge from './CountryRestrictionStatusBadge';
import BaseIcon from '@grain/web-components/icons/BaseIcon';
import { ToggleOff, ToggleOn } from 'iconsax-react';
import TwoButtonsDialog from '@grain/web-components/dialogs/TwoButtonsDialog';
import { cn } from '@grain/web-components';
import CountryConfigService from '../../services/CountryConfigService';

type CountryConfigsTableProps = {
  countryConfigs: AdminConsoleApiCountryConfig[];
  refreshConfigs: () => void;
};

type CountryConfigRow = {
  id: string;
  rating: CountryRating;
  manualApproval: boolean;
  code: string;
  name: string;
  isRestricted: boolean;
};

const ratingOptions = Object.values(CountryRating).map((rating) => ({
  text: rating,
  value: rating
}));

export default function CountryConfigsTable({ countryConfigs, refreshConfigs }: CountryConfigsTableProps) {
  const countryConfigRows = countryConfigs.map<CountryConfigRow>((config) => ({
    id: config.id,
    rating: config.rating,
    manualApproval: config.manualApproval,
    code: config.countryCode,
    name: countries.getName(config.countryCode, 'en') || '',
    isRestricted: config.isRestricted
  }));
  const sortedCountryConfigs = countryConfigRows.sort((a, b) => (a.name > b.name ? 1 : -1));

  const toggleManualApproval = useCallback(
    async (countryConfigId: string, manualApproval: boolean) => {
      await CountryConfigService.getInstance().updateCountryConfig(countryConfigId, { manualApproval });
      await refreshConfigs();
    },
    [refreshConfigs]
  );

  const columns: BaseTableColumnDef<CountryConfigRow>[] = [
    {
      accessorFn: (row) => row.id,
      id: 'id',
      header: 'ID'
    },
    {
      accessorFn: (row) => row.name,
      id: 'countryName',
      header: 'Country Name'
    },
    {
      accessorFn: (row) => row.code,
      id: 'countryCode',
      header: 'Country Code'
    },
    {
      cell: React.useCallback(
        // @ts-expect-error - moving to React 18
        ({ row: { original: countryConfig } }) => (
          <div className="rating-cell">
            <div className="rating-cell-text">{countryConfig.rating}</div>
            <SelectOptionDialog
              title="Select Country Rating"
              initialOption={countryConfig.rating}
              onCloseAfterSuccess={refreshConfigs}
              options={ratingOptions}
              onSubmit={async (status) =>
                CountryConfigService.getInstance().updateCountryConfig(countryConfig.id, { rating: status as CountryRating })
              }
              trigger={
                <BaseIcon>
                  <img src="/images/edit2.svg" className="image" alt="edit rating" />
                </BaseIcon>
              }
            />
          </div>
        ),
        [refreshConfigs]
      ),
      id: 'rating',
      header: 'Rating'
    },
    {
      cell: React.useCallback(
        // @ts-expect-error - moving to React 18
        ({ row: { original: countryConfig } }) => {
          const removeInstructions = `You are about to remove the manual approval for country ${countryConfig.code}. This might cause
           the country to become unavailable on the Grain platform depending on it's Rating.`;
          const approvalInstructions = `You are about to manually approve country ${countryConfig.code} for Grain usage,
            ignoring Rating based restrictions.`;
          const complianceNotice = 'IMPORTANT - Make sure you have approval from Compliance before you proceed.';
          return (
            <div className="manual-approval-cell">
              <div className={cn('manual-approval-cell-text', countryConfig.manualApproval ? 'toggled-on' : 'toggled-off')}>
                {countryConfig.manualApproval ? 'ON' : ''}
              </div>
              {countryConfig.manualApproval ? (
                <TwoButtonsDialog
                  title="Remove Manual Approval"
                  content={
                    <>
                      <div>{removeInstructions}</div>
                      <br />
                      <div>{complianceNotice}</div>
                    </>
                  }
                  onAccept={() => toggleManualApproval(countryConfig.id, false)}
                  trigger={
                    <div className="toggle-on-button">
                      <BaseIcon size={30}>
                        <ToggleOn variant="Bold" />
                      </BaseIcon>
                    </div>
                  }
                />
              ) : (
                <TwoButtonsDialog
                  title="Set Manual Approval"
                  content={
                    <>
                      <div>{approvalInstructions}</div>
                      <br />
                      <div>{complianceNotice}</div>
                    </>
                  }
                  onAccept={() => toggleManualApproval(countryConfig.id, true)}
                  trigger={
                    <BaseIcon size={30}>
                      <ToggleOff variant="Bold" />
                    </BaseIcon>
                  }
                />
              )}
            </div>
          );
        },
        [toggleManualApproval]
      ),
      id: 'manualApproval',
      header: 'Manual Approval'
    },
    {
      cell: React.useCallback(
        // @ts-expect-error - moving to React 18
        ({ row: { original: countryConfig } }) => <CountryRestrictionStatusBadge isRestricted={countryConfig.isRestricted} />,
        []
      ),
      id: 'restriction_status',
      header: 'Status'
    }
  ];

  return <BaseTable columns={columns} data={sortedCountryConfigs} paginated pageSize={5} fixedLayout />;
}
