import React from 'react';
import Page, { PageProps } from './Page';
import { cn } from '@grain/web-components';
import './DetailsPage.scss';

export default function DetailsPage(props: DetailsPageProps) {
  const { className, children, ...pageProps } = props;

  return (
    <Page className={cn('details-page-container', className)} {...pageProps}>
      {children}
    </Page>
  );
}

type DetailsPageProps = PageProps;
