import BaseTable, { BaseTableColumnDef } from '@grain/web-components/table/BaseTable';
import React from 'react';
import { asDateString } from '@grain/web-components/utils/formatting';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import { LoadingPerRow } from './ReportsManagementPage';

type ReportsTableProps = {
  reportsFiles: ReportFile[];
  onDownloadClicked: (reportFile: ReportFile, rowNumber: number) => void;
  onDownloadLoading: LoadingPerRow;
  loading: boolean;
};

export type ReportFile = {
  filePath: string;
  modified: number;
  filename: string;
};

export default function ReportsTable(props: ReportsTableProps) {
  const { reportsFiles, onDownloadClicked, loading, onDownloadLoading } = props;

  const columns: BaseTableColumnDef<ReportFile>[] = [
    {
      accessorFn: (reportFile) => reportFile.filename,
      id: 'fileName',
      header: 'File Name'
    },
    {
      accessorFn: (reportFile) => asDateString(reportFile.modified, true),
      id: 'modified',
      header: 'Created At'
    },
    {
      cell: React.useCallback(
        // @ts-expect-error - moving to React 18
        ({ row: { original: reportFile, index } }) => (
          <CTAButton text="Download" size="small" onClick={() => onDownloadClicked(reportFile, index)} loading={onDownloadLoading[index]} />
        ),
        [onDownloadClicked, onDownloadLoading]
      ),
      id: 'download',
      header: ''
    }
  ];
  return (
    <div className="reports-files-table-container">
      <BaseTable columns={columns} data={reportsFiles} paginated pageSize={10} fixedLayout loading={loading} />
    </div>
  );
}
