import { Hedge } from './hedge';
import {
  CycleSettlementFundTransfer,
  CycleSettlementInboundFundTransfer,
  HedgingSettlementInboundFundTransfer,
  HedgingSettlementOutboundFundTransfer,
  OffsetSettlementFundTransfer,
  OffsetSettlementInboundFundTransfer
} from './fund-transfer';
import { OffsetDetails } from './offset';

export type SetSettlementAndOverdueAtParams = {
  fromCurrency?: string;
  toCurrency: string;
  endDate: Date;
};

export enum SettlementStatus {
  Active = 'Active',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
  Overdue = 'Overdue',
  InProcess = 'InProcess'
}

export interface BaseSettlement {
  id: string;
  fundsReceivedAt?: Date;
  completedAt?: Date;

  getStatus(): SettlementStatus;
  setSettlementAndOverdueAt(params: SetSettlementAndOverdueAtParams): void;
}

export interface OffsetSettlement extends BaseSettlement {
  currency: string;
  hedge: Hedge;
  offsetDetails?: OffsetDetails;
  offsetSettlementOutboundFundTransfer?: OffsetSettlementFundTransfer;
  offsetSettlementInboundFundTransfer?: OffsetSettlementInboundFundTransfer;
}

export interface CollectionSettlement extends BaseSettlement {
  hedge: Hedge;
  hedgingSettlementOutboundFundTransfer?: HedgingSettlementOutboundFundTransfer;
  hedgingSettlementInboundFundTransfer?: HedgingSettlementInboundFundTransfer;
}

export interface CycleSettlement extends BaseSettlement {
  currency: string;
  hedges: Hedge[];
  offsetDetails: OffsetDetails[];
  fromHedgeEndAt: Date;
  toHedgeEndAt: Date;
  cycleSettlementOutboundFundTransfer?: CycleSettlementFundTransfer;
  cycleSettlementInboundFundTransfer?: CycleSettlementInboundFundTransfer;
}
