import { useQuery } from '@tanstack/react-query';
import { queries } from 'queries';

export function useGrainConfig() {
  const { data, isError, isLoading } = useQuery({
    ...queries.settings.grainConfig.detail
  });

  return {
    grainConfig: data,
    hasFailedLoadingGrainConfig: isError,
    isLoadingGrainConfig: isLoading
  };
}
