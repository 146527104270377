import React from 'react';
import './ViewModeIndicator.scss';
import { OrganizationType } from '@grain/core-types';

interface ViewModeIndicatorProps {
  mode: OrganizationType;
  size?: 'sm' | 'lg';
}

export function ViewModeIndicator(props: ViewModeIndicatorProps) {
  return <span className={`indicator ${props.mode.toLowerCase()} ${props.size || 'sm'}`}>&bull;</span>;
}

export function ViewModeLabelIndicator({ mode }: Pick<ViewModeIndicatorProps, 'mode'>) {
  return mode !== OrganizationType.Live ? <span className="label-indicator">{mode}</span> : null;
}
