import { BaseDropdownOption } from '@grain/web-components/forms/BaseDropdown';
import { POALIM_SUPPORTED_CURRENCIES } from '@grain/rate-utils';
import { FieldsGroup, GRAIN_PREFIX } from '@grain/web-components/mapping/BaseFieldsMapper';
import { BreakpointType } from '@grain/web-components/layout/use-window-dimensions';
import { MappingHedge } from '@grain/admin-console-api-types';

const ADDITIONAL_CURRENCY_OPTIONS: BaseDropdownOption[] = POALIM_SUPPORTED_CURRENCIES.map((currency) => ({
  value: `${GRAIN_PREFIX}${currency}`,
  text: currency
}));

const DATE_FIELDS_MAPPING: FieldsGroup<MappingHedge> = {
  fields: [
    { text: 'Creation Date', name: 'createdAt', calculable: false },
    { text: 'Agent Payment Due Date', name: 'endAt', calculable: false },
    { text: 'Cancel Date', name: 'cancelledAt', calculable: false }
  ],
  title: 'Dates',
  className: 'double'
};

const CURRENCY_FIELD = {
  calculable: false,
  additionalOptions: ADDITIONAL_CURRENCY_OPTIONS
};
const FOREIGN_CURRENCY_FIELDS_MAPPING: FieldsGroup<MappingHedge> = {
  fields: [
    { ...CURRENCY_FIELD, text: 'To Currency', name: 'toCurrency' },
    { text: 'To Currency Amount', name: 'toCurrencyAmount', calculable: false }
  ],
  title: 'Foreign Currency'
};

const BASE_CURRENCY_FIELDS_MAPPING: FieldsGroup<MappingHedge> = {
  fields: [
    {
      ...CURRENCY_FIELD,
      text: 'From Currency',
      name: 'fromCurrency',
      doubleBreakpoint: BreakpointType.Tablet
    }
  ],
  title: 'Base Currency'
};

export const PARTNER_FIELDS_MAPPING: FieldsGroup<MappingHedge> = {
  fields: [
    { text: 'Agent Id', name: 'externalCustomerId' },
    { text: 'Booking Number', name: 'externalTransactionId' }
  ],
  title: 'Partner'
};

export const ALL_MAPPING_FIELD_GROUPS = [
  DATE_FIELDS_MAPPING,
  FOREIGN_CURRENCY_FIELDS_MAPPING,
  BASE_CURRENCY_FIELDS_MAPPING,
  PARTNER_FIELDS_MAPPING
];

export const FROM_AMOUNT_FIELD_GROUPS: FieldsGroup<MappingHedge>[] = [
  {
    fields: [{ text: 'End date', name: 'endAt', calculable: false }],
    title: 'Dates',
    className: 'double'
  },
  {
    fields: [
      {
        ...CURRENCY_FIELD,
        text: 'From Currency',
        name: 'fromCurrency'
      },
      {
        text: 'From Currency Amount',
        name: 'fromCurrencyAmount',
        calculable: false
      }
    ],
    title: 'From Currency'
  },
  {
    fields: [{ ...CURRENCY_FIELD, text: 'To Currency', name: 'toCurrency' }],
    title: 'To Currency'
  },
  {
    fields: [
      { text: 'Customer ID', name: 'externalCustomerId' },
      { text: 'External Transaction ID', name: 'externalTransactionId' }
    ],
    title: 'Partner'
  }
];
