import { createQueryKeyStore } from '@lukemorales/query-key-factory';
import { TreasuryService } from 'services/TreasuryService';

export const treasuryQueries = createQueryKeyStore({
  wallets: {
    list: {
      queryKey: null,
      queryFn: () => TreasuryService.getInstance().getWallets()
    }
  }
});
