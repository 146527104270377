import { useQuery } from '@tanstack/react-query';
import { queries } from '../../../../queries';
import { hoursAsMilliseconds } from '@grain/formatters';
import { useDebounce } from '@grain/web-components/hooks';

export const useCurrencyPairs = (params: UseCurrencyPairsParams) => {
  const debouncedSearchTerm = useDebounce(params.searchTerm, 500);

  const { data, isLoading, isError } = useQuery({
    ...queries.partners.currencyPairs.list({
      ...params,
      searchTerm: debouncedSearchTerm
    }),
    staleTime: hoursAsMilliseconds(1)
  });

  return {
    currencyPairs: data,
    isLoadingCurrencyPairs: isLoading,
    isErrorLoadingCurrencyPairs: isError
  };
};

export type UseCurrencyPairsParams = Parameters<typeof queries.partners.currencyPairs.list>[0];
