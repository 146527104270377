import React, { ChangeEvent, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import BaseAutoComplete from '../forms/BaseAutoComplete';
import { Column } from '@grain/excel-parser';
import './BaseFieldsMapper.scss';
import ErrorDialog from '../dialogs/ErrorDialog';
import type { BaseDropdownOption } from '../forms/BaseDropdown';
import { cn } from '../utils';
import useWindowDimensions, { BreakpointType } from '../layout/use-window-dimensions';
import { MappingErrorTypes } from '@grain/api-utils';
import ExpandableMappingItem from './ExpandableMappingItem';
import BaseCheckbox from '../forms/BaseCheckbox';

export const GRAIN_PREFIX = 'grain__';
const CALCULATE_FOR_ME_OPTION = {
  value: GRAIN_PREFIX,
  text: 'Calculate for me'
};
const UNKNOWN_OPTION = { value: GRAIN_PREFIX, text: 'Unknown' };
const columnAsOptions = (columns: Column[]) => columns.map(({ id, header }) => ({ value: id, text: header }));

function splitToMappedAndConstantValues<T extends object>(mapping: T): [T, T] {
  const mappedValues = Object.fromEntries(Object.entries(mapping).filter(([, value]) => !value.startsWith(GRAIN_PREFIX))) as T;

  const constantValues = Object.fromEntries(
    Object.entries(mapping)
      .filter(([, value]) => value.startsWith(GRAIN_PREFIX))
      .map(([key, value]) => [key, value.split(GRAIN_PREFIX)[1]])
  ) as T;

  return [mappedValues, constantValues];
}

const getFieldValues = <K,>(fieldConfig: Partial<MapDataField<K>>) => {
  const { calculable, supportUnknown } = fieldConfig;
  if (calculable) {
    return CALCULATE_FOR_ME_OPTION.value;
  }
  if (supportUnknown) {
    return UNKNOWN_OPTION.value;
  }
  return '';
};

export default function BaseFieldsMapper<K>({
  formId,
  columns,
  fieldGroups,
  fileUrl,
  currentSheet,
  onColumnHover,
  mapFile,
  allowAdvancedSettings
}: BaseFieldsMapperProps<K>) {
  type T = Partial<Record<keyof K, string>>;

  const [mapping, setMapping] = useState<T>({} as T);
  const [errors, setErrors] = useState<T>({} as T);
  const [serverError, setServerError] = useState<string | null>(null);

  const mappingFields: MapDataFieldWithGroupName<K>[] = useMemo(
    () => [...fieldGroups.flatMap((fg) => fg.fields.map((field) => ({ ...field, groupName: fg.title })))],
    [fieldGroups]
  );

  const { breakpointType } = useWindowDimensions();
  const [skippingErrors, setSkippingErrors] = useState<boolean>(false);

  useEffect(() => {
    setMapping(
      mappingFields.reduce(
        (m, { name, ...fieldConfig }) => ({
          ...m,
          [name]: getFieldValues<K>(fieldConfig)
        }),
        {} as T
      )
    );
  }, [mappingFields, columns]);

  const onChange = (name: string, value: string) => {
    const newErrors = { ...errors } as T;
    delete newErrors[name as keyof K];
    setErrors(newErrors);

    setMapping({ ...mapping, [name]: value });
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const validationErrors = Object.fromEntries(mappingFields.map(({ name }) => [name, mapping[name] ? null : `Mapping is required`]));

    if (Object.values(validationErrors).some((v) => v)) {
      setErrors(validationErrors as T);
    } else {
      try {
        const [mappedValues, constantValues] = splitToMappedAndConstantValues<T>(mapping);
        await mapFile({
          fileUrl,
          sheetName: currentSheet,
          mappedValues,
          constantValues,
          skipRows: skippingErrors
        });
      } catch (e) {
        const { type: errorType } = e.extraParams || {};
        if (e.status === 400) {
          const { rowIndex, columnIndex, expectedType, key, value } = e.extraParams || {};
          const { header } = columns[columnIndex];
          const field = mappingFields.find(({ name }) => name === key)?.text;
          setServerError(
            `Could not parse a ${expectedType} value for "${field}" from "${value}" ` +
              `encountered at row ${+rowIndex + 1} and column "${header}"`
          );
        } else if (e.status === 422 && errorType === MappingErrorTypes.PartialRowData) {
          const { rowIndex, missingKeys } = e.extraParams || {};
          const missingColumnsNames = missingKeys
            .map((key: string) => mappingFields.find(({ name }) => name === key))
            .map((col: MapDataFieldWithGroupName<K>) => `${col.text} (${col.groupName})`);
          setServerError(`Row ${rowIndex} is missing required data in columns: ${missingColumnsNames}`);
        } else {
          setServerError(e.message);
        }
      }
    }
  };

  const mappingOptions = useMemo(() => columnAsOptions(columns), [columns]);
  const getMappingOptions = useCallback(
    (additionalOptions?: BaseDropdownOption[], calculable?: boolean, supportUnknown?: boolean) => {
      const allOptions = [...mappingOptions];
      if (calculable) allOptions.push(CALCULATE_FOR_ME_OPTION);
      if (supportUnknown) allOptions.push(UNKNOWN_OPTION);
      if (additionalOptions) allOptions.push(...additionalOptions);
      return allOptions;
    },
    [mappingOptions]
  );

  const onCheckboxClicked = (e: ChangeEvent<HTMLInputElement>) => setSkippingErrors(e.target.checked);

  const mappingDropdowns = (fieldsToMap: MapDataField<K>[]) =>
    fieldsToMap.map(({ text, name, calculable, supportUnknown, additionalOptions, doubleBreakpoint = BreakpointType.Mobile }) => (
      <div
        className={cn('mapping-field-container', {
          double: doubleBreakpoint >= breakpointType
        })}
        key={name as string}
      >
        <BaseAutoComplete
          name={name as string}
          labelText={text}
          errorMessage={errors[name]}
          options={getMappingOptions(additionalOptions, calculable, supportUnknown)}
          value={mapping[name]}
          onValueChange={onChange}
          onOptionHover={(o) => onColumnHover(columns.find((col) => col.header === o))}
          shadow
          small={breakpointType > BreakpointType.Tablet}
        />
      </div>
    ));

  return (
    <>
      {serverError && <ErrorDialog title="Could not map your data" content={serverError} onAccept={() => setServerError(null)} />}

      <form className="base-fields-mapper-container" onSubmit={onSubmit} id={formId}>
        <div className="mapping-section-container">
          {fieldGroups.map((fg) => (
            <div key={fg.title} className={cn('mapping-section', fg.className)}>
              <div className="mapping-section-header">{fg.title}</div>
              <div className="mapping-section-content">{mappingDropdowns(fg.fields)}</div>
            </div>
          ))}
        </div>
        {allowAdvancedSettings && (
          <ExpandableMappingItem title="Advanced">
            <BaseCheckbox id="mapping-advance" text="Skip rows with errors" checked={skippingErrors} onChange={onCheckboxClicked} />
          </ExpandableMappingItem>
        )}
      </form>
    </>
  );
}

export type BaseFieldsMapperProps<K> = {
  formId: string;
  columns: Column[];
  fieldGroups: FieldsGroup<K>[];
  onColumnHover: (column?: Column) => void;
  fileUrl: string;
  currentSheet: string;
  mapFile: (data: any) => void;
  allowAdvancedSettings?: boolean;
};

export type MapDataField<K> = {
  text: string;
  name: keyof K;
  calculable?: boolean;
  supportUnknown?: boolean;
  additionalOptions?: BaseDropdownOption[];
  doubleBreakpoint?: BreakpointType;
};

type MapDataFieldWithGroupName<K> = MapDataField<K> & { groupName: string };

export type FieldsGroup<K> = {
  fields: MapDataField<K>[];
  title: string;
  className?: 'double';
};
