import './DialogFormStep.scss';
import React, { FormEvent, ReactElement, useRef, useState } from 'react';
import CTAButton, { CTAButtonProps } from '../buttons/CTAButton';
import { cn } from '../utils';
import DialogBaseStep from './DialogBaseStep';

export default function DialogFormStep({ canSubmit, title, validate, onSubmit, children, className, submitButton }: DialogFormStepProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const formId = useRef<string>(`dialog-form-step-${Math.random().toString(36).slice(2)}`);

  const onSubmitClicked = async (e: FormEvent) => {
    e.preventDefault();
    const validationError = validate?.();
    if (validationError) {
      setError(validationError);
      return;
    }

    setLoading(true);
    try {
      await onSubmit();
    } catch (err) {
      setError(`Error occurred: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const buttonProps: CTAButtonProps = {
    type: 'submit',
    disabled: !canSubmit,
    loading
  };

  const submitButtonElement = submitButton ? React.cloneElement(submitButton, buttonProps) : <CTAButton text="Submit" {...buttonProps} />;

  return (
    <DialogBaseStep className={cn('dialog-form-step-container', className)} button={submitButtonElement} formId={formId.current}>
      <div className="form-title">{title}</div>
      <div className="form-container">
        <form onSubmit={onSubmitClicked} id={formId.current}>
          <div className="inputs-container">{children}</div>
        </form>
        {error && <div className="error">{error}</div>}
      </div>
    </DialogBaseStep>
  );
}

type DialogFormStepProps = {
  canSubmit: boolean;
  title: string;
  validate?: () => string;
  onSubmit: () => Promise<void> | void;
  children: React.ReactNode;
  className?: string;
  submitButton?: ReactElement;
};
