import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queries } from '../queries';
import PartnersService from '../services/PartnersService';
import { DashboardRole } from '@grain/core-types';

export interface InviteUserParams {
  firstName: string;
  lastName: string;
  role: DashboardRole;
  email: string;
  partnerId: string;
}

export const useInvitePartnerUser = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: invitePartnerUser,
    isError: partnerUserInvitationFailed,
    isPending: isInvitingPartnerUser,
    isSuccess: partnerUserInvitationSucceeded,
    reset: resetPartnerUserInvitationMutation,
    error: partnerUserInvitationError
  } = useMutation<void, Error, InviteUserParams>({
    mutationFn: (params) => PartnersService.getInstance().createPartnerUser(params),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queries.partners.users.list._def });
    }
  });

  return {
    invitePartnerUser,
    partnerUserInvitationFailed,
    isInvitingPartnerUser,
    partnerUserInvitationSucceeded,
    resetPartnerUserInvitationMutation,
    partnerUserInvitationError
  };
};
