declare global {
  interface Window {
    grainEnvironment: any;
  }
}

window.grainEnvironment = window.grainEnvironment || {};

export interface AnalyticsEnvironment {
  amplitudeApiKey?: string;
  sentryEnvironment: string;
  enableAnalytics: boolean;
  enableAlerts: boolean;
  chatraId: string;
  enableChat: boolean;
  enableScreenCapturing: boolean;
  fullstoryOrgID: string;
}

export type Environment = AnalyticsEnvironment;

export const analyticsEnvironment: AnalyticsEnvironment = {
  amplitudeApiKey: process.env.REACT_APP_AMPLITUDE_API_KEY || window.grainEnvironment.AMPLITUDE_API_KEY,
  enableAnalytics: process.env.REACT_APP_ENABLE_ANALYTICS === 'true' || window.grainEnvironment.ENABLE_ANALYTICS,
  enableAlerts: process.env.REACT_APP_ENABLE_ALERTS === 'true' || window.grainEnvironment.ENABLE_ALERTS,
  chatraId: process.env.REACT_APP_CHATRA_ID || window.grainEnvironment.CHATRA_ID,
  enableChat: process.env.REACT_APP_ENABLE_CHAT === 'true' || window.grainEnvironment.ENABLE_CHAT,
  enableScreenCapturing: process.env.REACT_APP_ENABLE_SCREEN_CAPTURING === 'true' || window.grainEnvironment.ENABLE_SCREEN_CAPTURING,
  fullstoryOrgID: process.env.REACT_APP_FULLSTORY_ORG_ID || window.grainEnvironment.FULLSTORY_ORG_ID,
  sentryEnvironment: process.env.REACT_APP_SENTRY_ENVIRONMENT || window.grainEnvironment.SENTRY_ENVIRONMENT
};

const env: Environment = { ...analyticsEnvironment };

export default env;
