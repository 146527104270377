import BaseTable, { BaseTableColumnDef, BaseTableColumnDefTextAlignType } from '@grain/web-components/table/BaseTable';
import React from 'react';
import { AdminConsoleExtendedJob } from '@grain/job-scheduler-types';
import TextButton from '@grain/web-components/buttons/TextButton';
import { asDateString, VALUE_MISSING } from '@grain/web-components/utils/formatting';
import StatusBadge from '@grain/web-components/badges/StatusBadge';
import './SchedulerJobsTable.scss';

type JobsTableProps = {
  jobs: AdminConsoleExtendedJob[];
  multipleJobs?: boolean;
  loading?: boolean;
};

export default function SchedulerJobsTable({ jobs, multipleJobs, loading }: JobsTableProps) {
  const columns: BaseTableColumnDef<AdminConsoleExtendedJob>[] = [
    {
      accessorFn: (job) => job.name,
      id: 'name',
      header: 'Name'
    },
    ...(multipleJobs
      ? [
          {
            accessorFn: (job: AdminConsoleExtendedJob) => job.description,
            id: 'description',
            header: 'Description'
          }
        ]
      : []),
    {
      accessorFn: (job) => asDateString(job.lastRunAt, true),
      id: 'lastRunAt',
      header: 'Run At'
    },
    {
      accessorFn: (job) => asDateString(job.lastFinishedAt, true),
      id: 'lastFinishedAt',
      header: 'Finished At'
    },
    {
      accessorFn: (job) => asDateString(job.failedAt as Date, true),
      id: 'failedAt',
      header: 'Failed At'
    },
    ...(!multipleJobs
      ? [
          {
            accessorFn: (job: AdminConsoleExtendedJob) => job.failReason || VALUE_MISSING,
            id: 'failReason',
            header: 'Fail Reason'
          }
        ]
      : [])
  ];

  const multipleJobsColumns = [
    {
      cell: React.useCallback(
        ({
          row: {
            // @ts-expect-error - moving to React 18
            original: { disabled }
          }
        }) => {
          const text = disabled ? 'Disabled' : 'Enabled';
          return <StatusBadge status={text.toLowerCase()} text={text} />;
        },
        []
      ),
      id: 'disabled',
      header: 'Status'
    },
    {
      cell: React.useCallback(
        ({
          row: {
            // @ts-expect-error - moving to React 18
            original: { id }
          }
        }) => <TextButton text="Manage" underline link={`/jobs/${id}`} withArrow />,
        []
      ),
      id: 'manage',
      header: '',
      textAlignType: BaseTableColumnDefTextAlignType.Custom
    }
  ];

  if (multipleJobs) {
    columns.push(...multipleJobsColumns);
  }

  return (
    <div className="scheduler-jobs-table-container">
      <BaseTable columns={columns} data={jobs} paginated pageSize={10} loading={loading} />
    </div>
  );
}
