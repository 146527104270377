import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Table, usePagination, useTable } from '@grain/web-components/table-v2';
import { PartnerFile } from '@grain/admin-console-api-types';
import { LoadingPerCell } from './PartnerFilesSection';
import { asDateString } from '@grain/web-components/utils/formatting';
import { sortByDates } from '@grain/web-components/table/sorting';

type PartnerFilesTableProps = {
  partnerFiles: PartnerFile[];
  onDownloadClicked: (fileName: string, rowIndex: number, cellIndex: number) => void;
  onDownloadLoading: LoadingPerCell;
  loading?: boolean;
};

const linkStyle = (onDownloadLoading: LoadingPerCell, rowIndex: number, cellIndex: number) => {
  return {
    textDecoration: 'underline',
    color: onDownloadLoading?.[rowIndex]?.[cellIndex] ? 'gray' : 'blue',
    cursor: onDownloadLoading?.[rowIndex]?.[cellIndex] ? 'not-allowed' : 'pointer'
  };
};

const createLinkColumn = (
  fileName: string,
  rowIndex: number,
  columnIndex: number,
  onDownloadClicked: (fileName: string, rowIndex: number, columnIndex: number) => void,
  onDownloadLoading: LoadingPerCell
) => (
  <a
    onClick={() => {
      onDownloadClicked(fileName, rowIndex, columnIndex);
    }}
    style={linkStyle(onDownloadLoading, rowIndex, columnIndex)}
  >
    {fileName}
  </a>
);

export default function PartnerFilesTable(props: PartnerFilesTableProps) {
  const { partnerFiles, loading, onDownloadClicked, onDownloadLoading } = props;

  const { paginationProps } = usePagination({
    initialPageSize: 5
  });

  const columnHelper = createColumnHelper<PartnerFile>();
  const columns = [
    columnHelper.accessor('originalFile', {
      header: 'Original File',
      cell: React.useCallback(
        // @ts-expect-error - moving to React 18
        ({ row, column }) => createLinkColumn(row.original.originalFile, row.index, column.id, onDownloadClicked, onDownloadLoading),
        [onDownloadClicked, onDownloadLoading]
      )
    }),
    columnHelper.accessor('resultFile', {
      header: 'Result File',
      cell: React.useCallback(
        // @ts-expect-error - moving to React 18
        ({ row, column }) => createLinkColumn(row.original.resultFile, row.index, column.id, onDownloadClicked, onDownloadLoading),
        [onDownloadClicked, onDownloadLoading]
      )
    }),
    columnHelper.accessor('uploadedTimestamp', {
      cell: (info) => asDateString(info.getValue(), true),
      header: 'Upload Date',
      sortingFn: sortByDates
    })
  ];

  const { table } = useTable(
    {
      columns,
      data: partnerFiles
    },
    paginationProps
  );

  return <Table table={table} loading={loading} />;
}
