import React, { ReactNode, useState } from 'react';
import './UserMenu.scss';
import { cn } from '../utils';
import { ArrowDown2 } from 'iconsax-react';
import NameTag, { UserDetailsType } from './NameTag';

export default function UserMenu(props: UserMenuProps) {
  const { children, userDetails } = props;
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={cn('user-menu-container', { expanded })}>
      <div className="user-details" onClick={toggleExpanded}>
        <NameTag userDetails={userDetails} />
        <ArrowDown2 className="expand-arrow" size={14} />
      </div>
      <div className="expanded-menu">{children}</div>
    </div>
  );
}

type UserMenuProps = {
  userDetails?: UserDetailsType;
  children: ReactNode;
};
