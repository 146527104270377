import React, { useCallback, useEffect, useState } from 'react';
import { Card } from '@grain/web-components/layout/Card';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@grain/web-components/accordion/Accordion';
import { PartnerSectionTitleMarker } from '../partner/PartnerSectionTitleMarker';
import PartnersService from '../../../services/PartnersService';
import { PartnerFile } from '@grain/admin-console-api-types';
import PartnerFilesTable from './PartnerFilesTable';

export type LoadingPerCell = {
  [rowNumber: number]: {
    [columnName: string]: boolean;
  };
};

export function PartnerFilesSection(props: PartnerUploadedFilesSectionProps) {
  const { partnerId } = props;
  const [partnerFiles, setPartnerFiles] = useState<PartnerFile[]>([]);
  const [loadingDownloadFile, setLoadingDownloadFile] = useState<LoadingPerCell>({} as LoadingPerCell);
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    (async function () {
      try {
        setLoadingFiles(true);
        const files = await PartnersService.getInstance().getPartnerFiles(partnerId);
        setPartnerFiles(files);
      } catch (e) {
        console.error('Failed to load uploaded files', e);
      } finally {
        setLoadingFiles(false);
      }
    })();
  }, [partnerId]);

  const downloadFile = useCallback(async (fileName: string, rowNumber: number, cellNumber: number) => {
    setLoadingDownloadFile((loadingDownloadFile) => ({
      ...loadingDownloadFile,
      [rowNumber]: {
        ...loadingDownloadFile[rowNumber],
        [cellNumber]: true
      }
    }));
    try {
      await PartnersService.getInstance().downloadPartnerFile(partnerId, fileName);
    } catch (err) {
      setErrorMessage(`Failed download file ${fileName} for partner ${partnerId}: ${err.message}`);
    } finally {
      setLoadingDownloadFile((loadingDownloadFile) => ({
        ...loadingDownloadFile,
        [rowNumber]: {
          ...loadingDownloadFile[rowNumber],
          [cellNumber]: false
        }
      }));
    }
  }, []);

  return (
    <Card>
      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <span className="font-medium text-secondary-grey flex items-center gap-2">
              <PartnerSectionTitleMarker color="success-green" />
              {`Files`}
            </span>
          </AccordionTrigger>

          <AccordionContent className="h-96">
            <PartnerFilesTable
              partnerFiles={partnerFiles}
              onDownloadClicked={downloadFile}
              onDownloadLoading={loadingDownloadFile}
              loading={loadingFiles}
            />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      {errorMessage && <label className="error-label">{errorMessage}</label>}
    </Card>
  );
}

type PartnerUploadedFilesSectionProps = {
  partnerId: string;
};
