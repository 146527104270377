import { cn } from '@grain/web-components';

export const FunctionalCurrencyChip = (props: FunctionalCurrencyChipProps) => {
  return (
    <div className={cn('flex items-center bg-indigo-300/10 rounded h-1 px-1 py-2', props.className)}>
      <span className="font-medium text-indigo-300 text-xxs leading-none">Functional</span>
    </div>
  );
};

export type FunctionalCurrencyChipProps = {
  className?: string;
};
