export function calcFromAmount(toAmount: number, rate: number): number {
  return +(toAmount * rate).toFixed(2);
}

export function calcToAmount(fromAmount: number, rate: number): number {
  return +(fromAmount / rate).toFixed(2);
}

export function calcRateFromAmounts(toAmount: number, fromAmount: number): number {
  return fromAmount / toAmount;
}

export function calcToAmountRoundDown(toAmount: number, rate: number): number {
  return Math.floor((toAmount / rate) * 100) / 100;
}
