import React from 'react';
import './BaseField.scss';
import { cn } from '../utils';
import { InputProps } from '@mui/material/Input';

export default function BaseField(props: BaseFieldProps) {
  const { name, labelText, errorMessage, children, required, hideErrorText, shadow, sideLabel } = props;

  const label = (
    <div className="label-container">
      <label htmlFor={name} className="field-label">
        {labelText}
      </label>
      {required && <div className="required-indicator">*</div>}
    </div>
  );

  return (
    <div key={labelText} className={cn('base-field-container', { 'shadow-field': shadow })}>
      <div className={cn('label-and-field', { side: sideLabel })}>
        {labelText && label}
        <div className="field-inner-container">{children}</div>
      </div>
      {!hideErrorText && <div className="field-error">{errorMessage}</div>}
    </div>
  );
}

interface BaseFieldProps extends FieldElementProps {
  children: React.ReactNode;
}

export interface FieldElementProps extends InputProps {
  name?: string;
  hideErrorText?: boolean;
  labelText?: string;
  errorMessage?: string;
  required?: boolean;
  shadow?: boolean;
  sideLabel?: boolean;
}
