import {
  GrainCurrencyPairHedgeForwardOrderProvider,
  GrainCurrencyPairHedgeForwardPointProvider,
  GrainCurrencyPairHedgeSpotRateProvider
} from '@grain/admin-console-api-types';
import { Provider } from './types';

const PRETTY_PROVIDER_NAMES: Record<Provider, string> = {
  [GrainCurrencyPairHedgeSpotRateProvider.Hapoalim]: 'Bank Hapoalim',
  [GrainCurrencyPairHedgeSpotRateProvider.JPMorgan]: 'JP Morgan',
  [GrainCurrencyPairHedgeSpotRateProvider.OpenExchangeRates]: 'Open Exchange Rates',
  [GrainCurrencyPairHedgeForwardOrderProvider.GrainManual]: 'Manual',
  [GrainCurrencyPairHedgeForwardPointProvider.Bloomberg]: 'Bloomberg',
  [GrainCurrencyPairHedgeForwardOrderProvider.Swiss]: 'Swiss Quote'
};

export function prettifyProviderName(providerName: string): string {
  return PRETTY_PROVIDER_NAMES[providerName as keyof typeof PRETTY_PROVIDER_NAMES] || providerName;
}
