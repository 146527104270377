import React from 'react';
import { cn } from '../utils';

export const Tag = (props: TagProps) => {
  const { value, className } = props;

  return (
    <div className={cn('w-fit h-fit leading-5 rounded border border-indigo-500/100 text-indigo-500/100 px-1', className)}>{value}</div>
  );
};

export type TagProps = {
  value: string;
  className?: string;
};
