import { useMutation, useQueryClient } from '@tanstack/react-query';
import PartnersService, { AddCurrencyPairParams } from 'services/PartnersService';
import { queries } from '../../../../queries';
import { notifySuccess } from '@grain/web-components/notifications/toasters';

export const useAddCurrencyPair = () => {
  const queryClient = useQueryClient();
  const { error, isError, isPending, isSuccess, mutateAsync } = useMutation<void, Error, AddCurrencyPairParams>({
    mutationFn: (params) => PartnersService.getInstance().addCurrencyPair(params),
    onSuccess: async () => {
      notifySuccess('Currency pair added successfully');
      await Promise.all([
        queryClient.refetchQueries({ queryKey: queries.partners.currencyPairs._def }),
        queryClient.refetchQueries({ queryKey: queries.partners.wallets._def }),
        queryClient.refetchQueries({ queryKey: queries.partner.detail._def })
      ]);
    }
  });

  return {
    addedCurrencyPairSuccessfully: isSuccess,
    failedToAddCurrencyPair: isError,
    addCurrencyPair: mutateAsync,
    isAddingCurrencyPair: isPending,
    addCurrencyPairError: error
  };
};
