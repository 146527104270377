import React from 'react';
import BaseTable, { BaseTableColumnDef } from '@grain/web-components/table/BaseTable';
import { ActionRejection, ActionType } from '@grain/core-types';
import TextButton from '@grain/web-components/buttons/TextButton';
import { asDateString } from '@grain/web-components/utils/formatting';
import { sortByDates } from '@grain/web-components/table/sorting';

export default function ActionRejectionsTable(props: ActionRejectionsTableProps) {
  const { actionRejections, showCustomerId } = props;

  const columns: BaseTableColumnDef<ActionRejection>[] = [
    {
      accessorFn: (actionRejection: ActionRejection) => asDateString(actionRejection.createdAt),
      sortingFn: sortByDates,
      id: 'createdAt',
      header: 'Rejected Date'
    },
    {
      accessorFn: (actionRejection: ActionRejection) => actionRejection.id,
      id: 'rejectionId',
      header: 'Action Rejection ID'
    },
    {
      accessorFn: (actionRejection: ActionRejection) =>
        actionRejection.actionType === ActionType.HedgeAcceptRejection ? 'Accept Hedge' : 'Get Quote',
      id: 'actionType',
      header: 'Action Type'
    },
    {
      accessorFn: (actionRejection: ActionRejection) => actionRejection.message,
      id: 'errorMessage',
      header: 'Error Message'
    },
    {
      // @ts-expect-error - moving to React 18
      cell: React.useCallback(({ row: { original: actionRejection } }) => <pre>{JSON.stringify(actionRejection.data, null, 2)}</pre>, []),
      id: 'extraData',
      header: 'Extra Data'
    }
  ];

  const customerColumns = [
    {
      cell: React.useCallback(
        ({
          row: {
            // @ts-expect-error - moving to React 18
            original: { companyId }
          }
        }) => <TextButton text={companyId} underline link={`/customers/${companyId}`} />,
        []
      ),
      id: 'customerId',
      header: 'Customer ID'
    },
    {
      accessorFn: (actionRejection: ActionRejection) => actionRejection.organizationId,
      id: 'organizationId',
      header: 'Organization ID'
    }
  ];

  if (showCustomerId) {
    columns.splice(1, 0, ...customerColumns);
  }

  return (
    <div className="action-rejections-table-container">
      <BaseTable columns={columns} data={actionRejections} paginated pageSize={5} />
    </div>
  );
}

interface ActionRejectionsTableProps {
  actionRejections: ActionRejection[];
  showCustomerId?: boolean;
}
