import { cn } from '@grain/web-components';

export const PartnerSettingStatus = (props: PartnerSettingStatusProps) => {
  const { settingEnabled, settingDisplayName, className } = props;

  if (!settingEnabled) {
    return (
      <button className="text-main-blue underline text-xs leading-none" onClick={props.onClick}>
        Enable {settingDisplayName}
      </button>
    );
  }

  return (
    <div className={cn(className, 'pt-px flex items-center text-success-green text-xs leading-none')}>
      <span className="text-lg mr-1">•</span> {settingDisplayName}
    </div>
  );
};

type PartnerSettingStatusProps = {
  settingEnabled: boolean;
  settingDisplayName: string;
  className?: string;
  onClick?: () => Promise<void> | void;
};
