import BaseTable, { BaseTableColumnDef } from '@grain/web-components/table/BaseTable';
import {
  Company,
  CompanyStatus,
  CompanyStatusAudit,
  CompanyStatusAuditResult,
  CompanyStatusAuditType
} from '@grain/admin-console-api-types';
import React from 'react';
import SelectOptionDialog from '@grain/web-components/dialogs/SelectOptionDialog';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import StatusBadge from '@grain/web-components/badges/StatusBadge';
import { mapCompanyStatusToLabel } from './CustomerStatusBadge';
import './CustomerStatusAuditsTable.scss';
import CustomersService from '../../services/CustomersService';

const statusOptions = (Object.values(CompanyStatus) as Array<CompanyStatus>).map((value) => ({
  value,
  text: mapCompanyStatusToLabel(value)
}));

const mapCompanyStatusAuditResultToLabel: (auditResult: CompanyStatusAuditResult) => string = (auditResult) =>
  ({
    [CompanyStatusAuditResult.Rejected]: 'Rejected',
    [CompanyStatusAuditResult.NoIssuesFound]: 'No issues found',
    [CompanyStatusAuditResult.FoundIssues]: 'Found issues'
  })[auditResult];

export default function CustomerStatusAuditsTable({ statusAudits, customer, onStatusChanged }: CustomerStatusAuditsTableProps) {
  const statusAuditsColumns: BaseTableColumnDef<CompanyStatusAudit>[] = [
    {
      cell: React.useCallback(
        ({
          row: {
            // @ts-expect-error - moving to React 18
            original: { result }
          }
        }) => <StatusBadge status={result} text={mapCompanyStatusAuditResultToLabel(result)} />,
        []
      ),
      id: 'result',
      header: 'Audit Result'
    },
    {
      accessorFn: (statusAudit) => statusAudit.type,
      id: 'type',
      header: 'Type'
    },
    {
      accessorFn: (statusAudit) => statusAudit.description,
      id: 'description',
      header: 'Description'
    }
  ];

  const countryStatusCheck = statusAudits?.find(({ type }) => type === CompanyStatusAuditType.Grain);
  const changeCustomerStatusDisabled = countryStatusCheck?.result === CompanyStatusAuditResult.Rejected;

  return (
    <BaseTable
      className="customer-status-audits-table"
      columns={statusAuditsColumns}
      data={statusAudits}
      paginated
      pageSize={5}
      paginatorSiblingElement={
        <SelectOptionDialog
          title="Change Status"
          initialOption={customer.status}
          onCloseAfterSuccess={onStatusChanged}
          options={statusOptions}
          onSubmit={(status) => CustomersService.getInstance().changeCustomerStatus(customer.id, status)}
          trigger={<CTAButton size="small" text="Change Customer Status" disabled={changeCustomerStatusDisabled} />}
        />
      }
    />
  );
}

type CustomerStatusAuditsTableProps = {
  customer: Company;
  statusAudits: CompanyStatusAudit[];
  onStatusChanged: () => void;
};
