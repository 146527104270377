import React, { useRef } from 'react';
import './SectionCard.scss';
import { cn } from '../utils';
import { Skeleton } from '@mui/material';

export default function SectionCard(props: SectionCardProps) {
  const {
    children,
    title,
    badge,
    subtitle,
    icon,
    iconBackgroundColor,
    className,
    headerRightComponent,
    loading,
    withBackground = true
  } = props;

  const iconChild = React.cloneElement(icon, { size: 16 });

  const contentRef = useRef<HTMLDivElement>(null);
  const lastContentHeightRef = useRef(0);
  lastContentHeightRef.current = contentRef?.current?.clientHeight || lastContentHeightRef.current;
  const loader = (
    <Skeleton
      variant="rectangular"
      animation="wave"
      className={cn({ rerender: lastContentHeightRef.current })}
      height={lastContentHeightRef.current}
    />
  );

  return (
    <div className={cn('section-card-container', { 'with-background': withBackground }, className)}>
      <div className="section-header">
        <div className="section-header-title-container">
          <div className="section-header-left">
            <div className="section-header-icon" style={{ backgroundColor: iconBackgroundColor }}>
              {iconChild}
            </div>
            <div className="section-header-title">{title}</div>
            {badge && <div className="section-header-badge">{badge}</div>}
          </div>
          <div className="section-header-right">{headerRightComponent}</div>
        </div>
        {subtitle && <div className="section-subtitle">{subtitle}</div>}
      </div>
      {loading ? (
        loader
      ) : (
        <div ref={contentRef} className="section-content">
          {children}
        </div>
      )}
    </div>
  );
}

export type SectionCardProps = {
  children: React.ReactNode;
  title: string;
  badge?: React.ReactElement;
  subtitle?: string;
  icon: React.ReactElement;
  iconBackgroundColor: string;
  className?: string;
  headerRightComponent?: React.ReactNode;
  loading?: boolean;
  withBackground?: boolean;
};
