export enum AuthorizationAction {
  Read = 100,
  Write = 200
}

export enum AdminConsoleScope {
  Compliance = 'compliance',
  Finance = 'finance',
  UserManagement = 'user-management',
  PartnerManagement = 'partner-management',
  CurrencyPairs = 'currency-pairs',
  GeneralSettings = 'general-settings',
  Basic = 'basic',
  JobScheduling = 'job-scheduling'
}

export enum DashboardScope {
  Hedging = 'hedging',
  Banking = 'banking',
  Reconciliation = 'reconciliation',
  ApiKeys = 'api-keys',
  CustomersManagement = 'customers-management',
  GeneralSettings = 'general-settings',
  Basic = 'basic',
  UserManagement = 'user-management'
}

export enum FXProtectionScope {
  Basic = 'basic'
}

export enum AdminConsoleRole {
  Default = 'default',
  Developer = 'developer',
  FinOps = 'finops',
  ComplianceOfficer = 'compliance-officer',
  SuperAdmin = 'super-admin'
}

export enum DashboardRole {
  Default = 'default',
  PartnerDeveloper = 'partner-developer',
  PartnerOwner = 'partner-owner',
  PartnerFinOps = 'partner-finops',
  PartnerAccounting = 'partner-accounting',
  GrainAdmin = 'grain-admin',
  Exploration = 'exploration'
}
