import { PartnerCurrencyPairsTable } from './PartnerCurrencyPairsTable';
import { Card } from '@grain/web-components/layout/Card';
import React, { useState } from 'react';
import { GrainCurrencyPair, HedgeSettlementType, MaxHedgeDurationInDays, Rates, TenorRate } from '@grain/core-types';
import { BaseDropdownOption } from '@grain/web-components/forms/BaseDropdown';
import { convertCurrencyPairToPairString } from '@grain/rate-utils';
import { AdminConsoleApiPartnerCurrencyPair } from '@grain/admin-console-api-types';
import { useGrainCurrencyPairs } from '../../../hooks';
import { useCurrencyPairs } from './hooks';
import { useQueryClient } from '@tanstack/react-query';
import { queries } from '../../../queries';
import { notifySuccess } from '@grain/web-components/notifications/toasters';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerActions
} from '@grain/web-components/accordion/Accordion';
import UploadHedgeOfflineDialog from './UploadHedgeOfflineDialog';
import { SeparatorRow } from '@grain/web-components/separator-row/SeparatorRow';
import { IconicButton } from '@grain/web-components/iconic-button/IconicButton';
import { Add, DocumentUpload } from 'iconsax-react';
import { AddCurrencyPairDialog } from './AddCurrencyPairDialog';
import { PartnerSectionTitleMarker } from '../partner/PartnerSectionTitleMarker';
import Searchbar from '@grain/web-components/searchbar/Searchbar';

export const PartnerCurrencyPairsSection = (props: PartnerCurrencyPairsSectionProps) => {
  const { addPair, partnerId } = props;
  const queryClient = useQueryClient();
  const { currencyPairs: partnerCurrencyPairs } = useCurrencyPairs({
    partnerId
  });
  const { grainCurrencyPairs } = useGrainCurrencyPairs();

  const [searchedCurrencyPairSearchTerm, setSearchedCurrencyPairSearchTerm] = useState<string>();

  const newCurrencyPairsOptions = getNewCurrencyPairsOptions(grainCurrencyPairs ?? [], partnerCurrencyPairs ?? []);

  const onUploadOfflineMarkupsSucceed = async (_file: File, status: string) => {
    const statusMessage = Number(status) === 200 ? 'successfully' : 'partially (please check audit logs for more details)';
    notifySuccess(`Offline markups file was uploaded ${statusMessage}`);
    await queryClient.refetchQueries({ queryKey: queries.partner.detail._def });
  };

  return (
    <Card>
      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <span className="font-medium text-secondary-grey flex items-center gap-2">
              <PartnerSectionTitleMarker color="secondary-purple" />
              Currency Pairs
            </span>

            <AccordionTriggerActions>
              <SeparatorRow separatorHeight="1rem">
                <UploadHedgeOfflineDialog
                  onUploadSucceed={onUploadOfflineMarkupsSucceed}
                  triggerComponent={<IconicButton icon={DocumentUpload} text="Upload Offline Markups" />}
                  partnerId={partnerId}
                />
                <AddCurrencyPairDialog
                  pairOptions={newCurrencyPairsOptions}
                  triggerComponent={<IconicButton icon={Add} text="Add Currency Pair" />}
                  onPairAddition={addPair}
                />
              </SeparatorRow>
            </AccordionTriggerActions>
          </AccordionTrigger>

          <AccordionContent>
            <Searchbar
              value={searchedCurrencyPairSearchTerm}
              className="mb-7"
              placeholder="Search currency pair"
              onChange={setSearchedCurrencyPairSearchTerm}
            />
            <PartnerCurrencyPairsTable
              partnerId={partnerId}
              maxHedgeDurationInDays={props.maxHedgeDurationInDays}
              additionalFilters={{ searchTerm: searchedCurrencyPairSearchTerm }}
              currencyTenorMarkup={props.currencyTenorMarkup}
              updateCurrencyPairTenorMarkup={props.updateCurrencyPairTenorMarkup}
              onPairRemove={props.removePair}
            />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};

type PartnerCurrencyPairsSectionProps = {
  partnerId: string;
  addPair: (pair: string, settlementType: HedgeSettlementType) => Promise<void>;
  removePair: (pair: AdminConsoleApiPartnerCurrencyPair) => Promise<void>;
  updateCurrencyPairTenorMarkup: (tenorRates: TenorRate, currencyPair: string) => void;
  currencyTenorMarkup?: Rates;
  maxHedgeDurationInDays: MaxHedgeDurationInDays;
};

function getNewCurrencyPairsOptions(
  grainCurrencyPairs: GrainCurrencyPair[],
  partnerCurrencyPairs: AdminConsoleApiPartnerCurrencyPair[]
): BaseDropdownOption[] {
  const grainPairsStr = grainCurrencyPairs.map(convertCurrencyPairToPairString);
  const currentPairsStr = partnerCurrencyPairs.map(convertCurrencyPairToPairString);

  return grainPairsStr.filter((pairStr) => !currentPairsStr.includes(pairStr)).map((pairStr) => ({ text: pairStr, value: pairStr }));
}
