// Redeclare the exact same type in place - used to overcome odd Typescript edge cases and errors
export type Identity<T> = {
  [P in keyof T]: T[P];
};

export class ExhaustiveCheck extends Error {
  constructor(exhaustiveCheck: never) {
    super(`Unhandled case: ${exhaustiveCheck}`);
  }
}
