import React, { ReactElement } from 'react';
import { Refresh } from 'iconsax-react';
import { ButtonRow } from '@grain/web-components/button-row/ButtonRow';
import { IconicButton } from '@grain/web-components/iconic-button/IconicButton';
import DetailsPage from 'components/layout/DetailsPage';
import { useSyncTreasury, useTreasuryWallets } from 'hooks';
import { WalletGrid } from '@grain/web-components/wallet-grid/WalletGrid';
import { WalletCard, WalletCardPaymentTypes } from '@grain/web-components/wallet-card/WalletCard';
import { partition, startCase } from 'lodash';
import { ViewMoreSection } from '@grain/web-components/view-more-section/ViewMoreSection';
import { TreasuryWallet } from '@grain/payments-service-types';
import { cn } from '@grain/web-components';
import StatusBadge from '@grain/web-components/badges/StatusBadge';
import './WalletsPage.scss';

const MAJOR_CURRENCIES_IN_ORDER = ['USD', 'EUR', 'GBP', 'CAD'];

export function WalletsPage(): ReactElement {
  const { treasuryWallets, isLoadingTreasuryWallets } = useTreasuryWallets();
  const { syncTreasury, isSyncingTreasury } = useSyncTreasury();

  const [settlementReadyWallets, otherWallets] = partition(treasuryWallets?.wallets || [], (wallet) => wallet.isSettlementReady);

  settlementReadyWallets.sort((a, b) => MAJOR_CURRENCIES_IN_ORDER.indexOf(b.currency) - MAJOR_CURRENCIES_IN_ORDER.indexOf(a.currency));

  return (
    <DetailsPage subtitle="Manage grain's treasury wallets" title="Wallets" loading={isLoadingTreasuryWallets || isSyncingTreasury}>
      <div className="wallets-content">
        <div className="wallets-controls">
          <span className="wallets-controls-title">Treasury Wallets</span>
          <ButtonRow>
            <IconicButton icon={Refresh} onClick={syncTreasury} text="Sync treasury" />
          </ButtonRow>
        </div>
        <WalletGrid>
          {settlementReadyWallets.map((wallet) => (
            <TreasuryWalletCard key={wallet.currency} wallet={wallet} />
          ))}
        </WalletGrid>
        {otherWallets.length > 0 && (
          <ViewMoreSection title="Other Wallets">
            <WalletGrid>
              {otherWallets.map((wallet) => (
                <TreasuryWalletCard key={wallet.currency} wallet={wallet} />
              ))}
            </WalletGrid>
          </ViewMoreSection>
        )}
      </div>
    </DetailsPage>
  );
}

interface TreasuryWalletCardProps {
  wallet: TreasuryWallet;
}

function TreasuryWalletCard(props: TreasuryWalletCardProps): ReactElement {
  const { wallet } = props;
  const { isSettlementReady } = wallet;

  return (
    <WalletCard
      balance={wallet.externalBalance}
      currency={wallet.currency}
      values={[
        {
          title: 'Settlement supported',
          value: (
            <StatusBadge
              className={cn('settlement-ready-badge', {
                success: isSettlementReady
              })}
              status={startCase(String(isSettlementReady))}
            />
          )
        },
        {
          title: 'Supported Payments',
          value: <WalletCardPaymentTypes paymentTypes={wallet.paymentTypes} />
        }
      ]}
    />
  );
}
