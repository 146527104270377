export interface IRatesSnapshot {
  revision: number;
  rates: Rates;
  validUntil: Date;
}

type OfflineCurrencyPair = string;

export enum Tenor {
  Spot = '0',
  Week = '7',
  TwoWeeks = '14',
  Month = '30',
  TwoMonths = '60',
  ThreeMonths = '90',
  FourMonths = '120',
  FiveMonths = '150',
  SixMonths = '180',
  SevenMonths = '210',
  EightMonths = '240',
  NineMonths = '270',
  TenMonths = '300',
  ElevenMonths = '330',
  OneYear = '360',
  FifteenMonths = '450',
  EighteenMonths = '540',
  TwentyOneMonths = '630',
  TwoYears = '720'
}

export type TenorRate = Record<Tenor, number>;
export type Rates = Record<OfflineCurrencyPair, TenorRate>;

export const oneYearTenors = [
  Tenor.Week,
  Tenor.TwoWeeks,
  Tenor.Month,
  Tenor.TwoMonths,
  Tenor.ThreeMonths,
  Tenor.FourMonths,
  Tenor.FiveMonths,
  Tenor.SixMonths,
  Tenor.SevenMonths,
  Tenor.EightMonths,
  Tenor.NineMonths,
  Tenor.TenMonths,
  Tenor.ElevenMonths,
  Tenor.OneYear
] as const;

export const tenorsBetweenOneAndTwoYears = [Tenor.FifteenMonths, Tenor.EighteenMonths, Tenor.TwentyOneMonths, Tenor.TwoYears] as const;
export const twoYearsTenors = [...oneYearTenors, ...tenorsBetweenOneAndTwoYears] as const;
export type OneYearTenor = (typeof oneYearTenors)[number];
export type TwoYearsTenor = (typeof twoYearsTenors)[number];

export type OneYearTenorRate = Record<OneYearTenor, number>;
export type TwoYearsTenorRate = Record<TwoYearsTenor, number>;
export type TenorsBetweenOneAndTwoYears = Record<(typeof tenorsBetweenOneAndTwoYears)[number], number>;
export type OneYearRates = Record<OfflineCurrencyPair, OneYearTenorRate>;
export type TwoYearsRates = Record<OfflineCurrencyPair, TwoYearsTenorRate>;

export const isOneYearRates = (rates: OneYearRates | TwoYearsRates): rates is OneYearRates => {
  return !Object.values(rates).some((tenorValues) => Tenor.TwoYears in tenorValues);
};

export const isTwoYearsRates = (rates: OneYearRates | TwoYearsRates): rates is TwoYearsRates => {
  return Object.values(rates).every((tenorValues) => Tenor.TwoYears in tenorValues);
};
