import {
  AdminConsoleApiPartner,
  LiquidityProviderAccountOverride,
  OrganizationType,
  PaymentBeneficiaryType
} from '@grain/admin-console-api-types';
import { ReactComponent as MoreIcon } from '../../icons/more.svg';
import React, { useRef, useState } from 'react';
import { alpha, Backdrop, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ToolsService from '../../services/ToolsService';
import UploadHedgesDialog, { AmountDirection } from './manual-hedging/UploadHedgesDialog';
import { hasLiveApiKeys, isConnectedToBankingVendor } from './utils';
import UploadTransactionsDialog from './transactions/UploadTransactionsDialog';
import PennyTestDialog from './PennyTestDialog';
import { useExecutePennyTestToPartner, useWithdrawFundsToGrainTreasury } from '../../hooks';
import { SeparatorRow } from '@grain/web-components/separator-row/SeparatorRow';
import { KeyValuePair } from '@grain/web-components/key-value-pair/KeyValuePair';
import { Card } from '@grain/web-components/layout/Card';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import { Add } from 'iconsax-react';
import { toSentenceCase } from '@grain/formatters';
import EditPartnerConfigDialog from './EditPartnerConfigDialog';
import { PartnerTransferMateSetting } from './partner/PartnerTransferMateSetting';
import { PartnerApiKeysSetting } from './partner/PartnerApiKeysSetting';
import { useChangeTransferMatePassword } from './partner/hooks/useChangeTransferMatePassword';
import ChangeTransferMatePasswordDialog from '../common/ChangeTransferMatePasswordDialog';
import { ViewModeLabelIndicator } from '@grain/web-components/view-mode-indicator/ViewModeIndicator';

enum ModalType {
  UploadHedges = 'UploadHedges',
  UploadTransactions = 'UploadTransactions',
  PennyTestToGrainTreasury = 'PennyTestToGrainTreasury',
  PennyTestToPartner = 'PennyTestToPartner',
  ChangeTransferMatePassword = 'ChangeTransferMatePassword'
}

export const PartnerPageHeader = (props: PartnerPageHeaderProps) => {
  const [isPartnerActionsMenuOpen, setIsPartnerActionsMenuOpen] = useState(false);
  const [loadingTransactionsFile, setLoadingTransactionsFile] = useState(false);
  const [openModalType, setOpenModalType] = useState<ModalType>();
  const isTransferMateEnabled = isConnectedToBankingVendor(props.partner.organizations ?? []);
  const isLiveHidden = props.partner.config.isLiveHidden;
  const anchorElement = useRef(null);
  const navigate = useNavigate();
  const {
    withdrawFundsToGrainBeneficiary: executePennyTestToGrainTreasury,
    withdrawalFailed: pennyTestToTreasuryExecutionFailed,
    isWithdrawing: isExecutingPennyTestToTreasury,
    withdrawalSucceeded: pennyTestToTreasuryExecutionSucceeded,
    resetWithdrawFundsToGrainMutation: resetExecutePennyTestToTreasuryMutation,
    withdrawalError: pennyTestToTreasuryExecutionError
  } = useWithdrawFundsToGrainTreasury();
  const {
    executePennyTestToPartner,
    isExecutingPennyTest: isExecutingPennyTestToPartner,
    pennyTestExecutionError: pennyTestToPartnerExecutionError,
    pennyTestExecutionFailed: pennyTestToPartnerExecutionFailed,
    pennyTestExecutionSucceeded: pennyTestToPartnerExecutionSucceeded,
    resetExecutePennyTestToPartnerMutation
  } = useExecutePennyTestToPartner();
  const { changeTransferMatePassword, resetTransferMatePasswordMutation } = useChangeTransferMatePassword();

  const downloadTransactionsFile = () => {
    (async function () {
      setLoadingTransactionsFile(true);
      try {
        await ToolsService.getInstance().getTransactionsFile(props.partner.id);
      } catch (err) {
        console.error(err);
      }
    })();
  };

  const onUploadHedgesFileSucceed = (params: { amountDirection: AmountDirection; file: File; fileUrl: string; pathname?: string }) => {
    const { file, fileUrl, amountDirection } = params;
    const pathname = amountDirection === AmountDirection.FromAmount ? '/fromAmount' : '';
    setOpenModalType(undefined);
    navigate(`/partners/${props.partner.id}/manual-hedging${pathname}`, {
      state: {
        uploadedFile: file,
        uploadedFileUrl: fileUrl,
        partnerName: props.partner.name
      }
    });
  };

  const onUploadTransactionsFileSucceed = (params: { file: File; fileUrl: string }) => {
    const { file, fileUrl } = params;
    setOpenModalType(undefined);
    navigate(`/partners/${props.partner.id}/upload-transactions`, {
      state: {
        uploadedFile: file,
        uploadedFileUrl: fileUrl,
        partnerName: props.partner.name
      }
    });
  };

  return (
    <>
      <Card>
        <div className="flex items-baseline gap-3">
          <div className="flex flex-col gap-1">
            <SeparatorRow className="flex items-center" separatorHeight="0.75rem">
              <span className="text-primary text-xl font-bold leading-none">{props.partner.name}</span>

              {isLiveHidden ? (
                <ViewModeLabelIndicator mode={OrganizationType.Sandbox} />
              ) : (
                <>
                  <PartnerTransferMateSetting partner={props.partner} />
                  <PartnerApiKeysSetting partner={props.partner} />
                </>
              )}
            </SeparatorRow>
          </div>

          <div className="ml-auto flex items-center gap-2">
            <CTAButton
              size="small"
              leadingIcon={<Add size={20} />}
              text={<span className="font-medium">Add Hedges</span>}
              disabled={!hasLiveApiKeys(props.partner.organizations || [])}
              onClick={() => setOpenModalType(ModalType.UploadHedges)}
            />

            <EditPartnerConfigDialog partnerId={props.partner.id} initialData={props.partner.config} />

            <MoreIcon
              ref={anchorElement}
              className="text-secondary-grey cursor-pointer ml-2 mr-1"
              onClick={() => setIsPartnerActionsMenuOpen(!isPartnerActionsMenuOpen)}
            />
          </div>
        </div>

        <div className="bg-grey-bg bg-opacity-30 mt-9">
          <SeparatorRow separatorHeight="2.5rem" gap="1rem" separatorColor="#0302290D">
            <KeyValuePair
              className="text-sm"
              titleClassName="font-normal"
              title="Functional Currency"
              value={props.partner.config.functionalCurrency || 'None'}
            />

            <KeyValuePair
              className="text-sm"
              titleClassName="font-normal"
              title="Settlement Cycle"
              value={props.partner.config.cycleConfig ? toSentenceCase(props.partner.config.cycleConfig) : 'N/A'}
            />

            <KeyValuePair
              className="text-sm"
              titleClassName="font-normal"
              title="Liquidity Provider Account"
              value={
                props.partner.config.liquidityProviderOverrideAccount != LiquidityProviderAccountOverride.None
                  ? toSentenceCase(props.partner.config.liquidityProviderOverrideAccount)
                  : 'Grain'
              }
            />

            <KeyValuePair
              className="text-sm"
              titleClassName="font-normal"
              title="Forward Purchase Strategy"
              value={toSentenceCase(props.partner.config.forwardPurchaseStrategy)}
            />

            <KeyValuePair
              className="text-sm"
              titleClassName="font-normal"
              title="Reconciliation enabled"
              value={props.partner.config.enableReconciliation ? 'True' : 'False'}
            />
          </SeparatorRow>
        </div>
      </Card>

      <Backdrop
        sx={{
          backgroundColor: alpha('#000000', 0.25),
          zIndex: (theme) => theme.zIndex.drawer + 3
        }}
        open={isPartnerActionsMenuOpen}
      >
        <Menu
          anchorEl={anchorElement.current}
          open={isPartnerActionsMenuOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClick={() => setIsPartnerActionsMenuOpen(false)}
          onClose={() => setIsPartnerActionsMenuOpen(false)}
        >
          <MenuItem onClick={() => navigate(`forward-instructions?org=${OrganizationType.Live}`)}>Forward Purchase Instructions</MenuItem>

          <MenuItem disabled={loadingTransactionsFile} onClick={downloadTransactionsFile}>
            Download Transactions File
          </MenuItem>

          <MenuItem onClick={() => setOpenModalType(ModalType.UploadTransactions)}>Upload Live Transactions</MenuItem>

          <MenuItem onClick={() => setOpenModalType(ModalType.PennyTestToGrainTreasury)}>Execute penny test to Grain</MenuItem>

          <MenuItem onClick={() => setOpenModalType(ModalType.PennyTestToPartner)}>Execute penny test to Partner</MenuItem>
          {isTransferMateEnabled && (
            <MenuItem onClick={() => setOpenModalType(ModalType.ChangeTransferMatePassword)}>Change TransferMate Password</MenuItem>
          )}
        </Menu>
      </Backdrop>

      <UploadHedgesDialog
        open={openModalType === ModalType.UploadHedges}
        partnerId={props.partner.id}
        onUploadSucceed={(file, fileUrl, amountDirection) => onUploadHedgesFileSucceed({ file, fileUrl, amountDirection })}
        onClose={() => setOpenModalType(undefined)}
      />

      <UploadTransactionsDialog
        open={openModalType === ModalType.UploadTransactions}
        partnerId={props.partner.id}
        onUploadSucceed={(file: File, fileUrl: string) => onUploadTransactionsFileSucceed({ file, fileUrl })}
        onClose={() => setOpenModalType(undefined)}
      />

      <PennyTestDialog
        open={openModalType === ModalType.PennyTestToGrainTreasury}
        partnerId={props.partner.id}
        paymentBeneficiaryType={PaymentBeneficiaryType.PartnerOwnedByGrain}
        error={pennyTestToTreasuryExecutionError}
        isExecuting={isExecutingPennyTestToTreasury}
        isSuccess={pennyTestToTreasuryExecutionSucceeded}
        isFailure={pennyTestToTreasuryExecutionFailed}
        onConfirm={(partnerId, amount, currency) =>
          executePennyTestToGrainTreasury({
            partnerId,
            amount,
            currency
          })
        }
        onClose={() => {
          resetExecutePennyTestToTreasuryMutation();
          setOpenModalType(undefined);
        }}
      />

      <PennyTestDialog
        open={openModalType === ModalType.PennyTestToPartner}
        partnerId={props.partner.id}
        paymentBeneficiaryType={PaymentBeneficiaryType.GrainTreasury}
        isExecuting={isExecutingPennyTestToPartner}
        isSuccess={pennyTestToPartnerExecutionSucceeded}
        isFailure={pennyTestToPartnerExecutionFailed}
        error={pennyTestToPartnerExecutionError}
        onConfirm={(partnerId, amount, currency) =>
          executePennyTestToPartner({
            partnerId,
            amount,
            currency
          })
        }
        onClose={() => {
          resetExecutePennyTestToPartnerMutation();
          setOpenModalType(undefined);
        }}
      />

      <ChangeTransferMatePasswordDialog
        open={openModalType === ModalType.ChangeTransferMatePassword}
        dialogTitle="Change TransferMate Password"
        onChangeTransferMatePasswordRequested={(payload) =>
          changeTransferMatePassword({
            partnerId: props.partner.id,
            data: payload
          })
        }
        onClose={() => {
          resetTransferMatePasswordMutation();
          setOpenModalType(undefined);
        }}
      />
    </>
  );
};

type PartnerPageHeaderProps = {
  partner: AdminConsoleApiPartner;
};
