import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notifySuccess } from '@grain/web-components/notifications/toasters';
import { AddGrainCurrencyPairRequestBody } from '@grain/admin-console-api-types';
import GrainSettingsService from '../../../services/GrainSettingsService';
import { queries } from '../../../queries';

export const useEditGrainCurrencyPair = () => {
  const queryClient = useQueryClient();
  const { error, isError, isPending, isSuccess, mutateAsync } = useMutation<void, Error, { id: string } & AddGrainCurrencyPairRequestBody>({
    mutationFn: (params: { id: string } & AddGrainCurrencyPairRequestBody) => {
      const { id, ...rest } = params;
      return GrainSettingsService.getInstance().editGrainCurrencyPair(id, rest);
    },
    onSuccess: async () => {
      notifySuccess('Grain currency pair changes were successfully saved');
      await queryClient.refetchQueries({ queryKey: queries.settings.grainCurrencyPairs.all._def });
    }
  });

  return {
    isSuccessEditGrainCurrencyPair: isSuccess,
    isErrorEditGrainCurrencyPair: isError,
    editGrainCurrencyPairAsync: mutateAsync,
    isLoadingEditGrainCurrencyPair: isPending,
    errorEditGrainCurrencyPair: error
  };
};
