import BaseTable, { BaseTableColumnDef } from '@grain/web-components/table/BaseTable';
import { AdminConsoleRole, AdminConsoleUser } from '@grain/admin-console-api-types';
import React from 'react';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import SelectOptionDialog from '@grain/web-components/dialogs/SelectOptionDialog';
import UsersService from '../../services/UsersService';

type UsersTableProps = {
  users: AdminConsoleUser[];
  onCloseAfterSuccess: () => void;
};

const roleOptions = (Object.values(AdminConsoleRole) as Array<AdminConsoleRole>).map((value) => ({
  value,
  text: getRoleDisplayName(value)
}));

function getRoleDisplayName(role: AdminConsoleRole): string {
  switch (role) {
    case AdminConsoleRole.Default:
      return 'Default User';
    case AdminConsoleRole.ComplianceOfficer:
      return 'Compliance Officer';
    case AdminConsoleRole.FinOps:
      return 'FinOps';
    case AdminConsoleRole.Developer:
      return 'Developer';
    case AdminConsoleRole.SuperAdmin:
      return 'Super Admin';
    default:
      return `Unknown Value - ${role}`;
  }
}

const onSubmit = async (userId: string, role: string) => {
  await UsersService.getInstance().updateUserRole(userId, role as AdminConsoleRole);
};

export default function UsersTable({ users, onCloseAfterSuccess }: UsersTableProps) {
  const columns: BaseTableColumnDef<AdminConsoleUser>[] = [
    {
      accessorFn: (user: AdminConsoleUser) => user.id,
      id: 'id',
      header: 'ID'
    },
    {
      accessorFn: (user: AdminConsoleUser) => user.firstName,
      id: 'firstName',
      header: 'First Name'
    },
    {
      accessorFn: (user: AdminConsoleUser) => user.lastName,
      id: 'lastName',
      header: 'Last Name'
    },
    {
      accessorFn: (user: AdminConsoleUser) => getRoleDisplayName(user.role),
      id: 'role',
      header: 'Role'
    },
    {
      cell: React.useCallback(
        ({
          row: {
            // @ts-expect-error - moving to React 18
            original: { id, role }
          }
        }) => (
          <SelectOptionDialog
            title="Change User Role"
            initialOption={role}
            onCloseAfterSuccess={onCloseAfterSuccess}
            options={roleOptions}
            onSubmit={(newRole) => onSubmit(id, newRole)}
            trigger={<CTAButton size="small" text="Change Role" />}
          />
        ),
        [onCloseAfterSuccess]
      ),
      id: 'view',
      header: ''
    }
  ];

  return (
    <div className="users-table-container">
      <BaseTable columns={columns} data={users} paginated fixedLayout />
    </div>
  );
}
