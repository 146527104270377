import { TenorRate } from './rates-snapshot';
import { QuoteSource } from './hedge-proposal';

export enum CurrencyPairQuoteStatus {
  ok = 'ok',
  error = 'error'
}

export type CurrencyPairStatus = {
  currency: string;
  spotStatus: CurrencyPairSpotStatus;
  hedgeStatus: CurrencyPairHedgeStatus;
};

export type CurrencyPairSpotStatus = {
  status: CurrencyPairQuoteStatus;
  quote?: number;
  sourceQuote?: QuoteSource;
};

export type CurrencyPairHedgeStatus = {
  status: CurrencyPairQuoteStatus;
  rates?: TenorRate;
};

export enum GrainCurrencyPairHedgeSpotRateProvider {
  Hapoalim = 'hapoalim',
  JPMorgan = 'jpmorgan',
  Swiss = 'swiss',
  OpenExchangeRates = 'open_exchange_rates'
}

export enum GrainCurrencyPairHedgeForwardPointProvider {
  Bloomberg = 'bloomberg'
}

export enum GrainCurrencyPairHedgeForwardOrderProvider {
  Hapoalim = 'hapoalim',
  Swiss = 'swiss',
  GrainManual = 'grain_manual'
}

export enum GrainCurrencyPairProviderStatus {
  Healthy = 'healthy',
  Down = 'down'
}
