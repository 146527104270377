import React, { PropsWithChildren, ReactElement } from 'react';
import './Card.scss';
import { cn } from '../utils';

export type CardProps = PropsWithChildren<{
  className?: string;
  onClick?(): void;
}>;

export function Card(props: CardProps): ReactElement {
  const { onClick } = props;

  return (
    <div
      className={cn('card', props.className, {
        'card-clickable': Boolean(onClick)
      })}
      onClick={onClick}
    >
      {props.children}
    </div>
  );
}

export function StretchCard(props: CardProps): ReactElement {
  const { onClick, className } = props;

  return (
    <Card onClick={onClick} className={cn('grow', className)}>
      {props.children}
    </Card>
  );
}
