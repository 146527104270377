import { Organization } from './organization';
import { FlatJson } from './json';

export enum CompanyStatus {
  Pending = 'pending',
  Approved = 'approved',
  Rejected = 'rejected'
}

export interface BaseCompanyEntity {
  id: string;
  name: string;
  address: string;
  city?: string;
  country?: string;
  state?: string;
  externalCustomerId?: string;
  contactEmail?: string;
  contactFullName?: string;
  contactPhoneNumber?: string;
  taxId?: string;
  organizationId?: string;
  organization?: Organization;
  extraFields?: FlatJson;
}

export interface Company extends BaseCompanyEntity {
  industry: string;
  status: CompanyStatus;
  manualMarkup?: number;
  payingEntity?: PayingEntity;
}

export interface PayingEntity extends BaseCompanyEntity {
  payingForCompanies: Company[];
  company?: Company;
}
