import { useMutation, useQueryClient } from '@tanstack/react-query';
import PartnersService, { ChangeTransferMatePasswordParams } from 'services/PartnersService';
import { queries } from '../../../../queries';
import { notifySuccess } from '@grain/web-components/notifications/toasters';

export const useChangeTransferMatePassword = () => {
  const queryClient = useQueryClient();
  const { error, isError, isPending, isSuccess, mutateAsync, reset } = useMutation({
    mutationFn: (params: ChangeTransferMatePasswordParams) => PartnersService.getInstance().changePartnerTransferMatePassword(params),
    onSuccess: async () => {
      notifySuccess('Successfully changed TransferMate password');
      await queryClient.refetchQueries({ queryKey: queries.partner.detail._def });
    }
  });

  return {
    changedTransferMatePasswordSuccessfully: isSuccess,
    failedToChangeTransferMatePassword: isError,
    changeTransferMatePassword: mutateAsync,
    isChangingTransferMatePassword: isPending,
    changeTransferMatePasswordError: error,
    resetTransferMatePasswordMutation: reset
  };
};
