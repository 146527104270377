import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queries } from '../queries';
import PartnersService from '../services/PartnersService';
import { DashboardRole } from '@grain/core-types';

export interface EditUserParams {
  id: string;
  firstName: string;
  lastName: string;
  role: DashboardRole;
  partnerId: string;
}

export const useEditPartnerUser = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: editPartnerUser,
    isError: partnerUserEditFailed,
    isPending: isEditingPartnerUser,
    isSuccess: partnerUserEditSucceeded,
    reset: resetPartnerUserEditMutation,
    error: partnerUserEditError
  } = useMutation<void, Error, EditUserParams>({
    mutationFn: (params) => PartnersService.getInstance().updatePartnerUser(params),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queries.partners.users.list._def });
    }
  });

  return {
    editPartnerUser,
    partnerUserEditFailed,
    isEditingPartnerUser,
    partnerUserEditSucceeded,
    resetPartnerUserEditMutation,
    partnerUserEditError
  };
};
