import Page from '../layout/Page';
import React, { useCallback, useEffect, useState } from 'react';
import { ArrowCircleDown2 } from 'iconsax-react';
import colors from '@grain/web-components/styles/colors.scss';
import SectionCard from '@grain/web-components/layout/SectionCard';
import {
  BaseFundTransfer,
  FundTransferType,
  HedgingSpotOutboundFundTransfer,
  TreasuryInboundFundTransfer,
  TreasuryOutboundFundTransfer
} from '@grain/admin-console-api-types';
import './FundTransfersPage.scss';
import CTAButton from '@grain/web-components/buttons/CTAButton';
import BaseTabs from '@grain/web-components/tabs/BaseTabs';
import FundTransfersTable from './FundTransfersTable';
import FundTransfersService from '../../services/FundTransfersService';
import { ALL_TRANSFERS_TAB, getFundTransfersGroupedByTableTabs, getFundTransferTableTabs } from './get-fund-transfer-table-tabs';
import ManagedFiltersForm from '@grain/web-components/filters/ManagedFiltersForm';
import { getFundTransferPartnerName } from './get-fund-transfer-partner-name';
import { notifySuccess } from '@grain/web-components/notifications/toasters';

interface FundTransfersFiltersState {
  externalId?: string;
  partnerName?: string;
}

type BaseFundTransferWithPartnerName = BaseFundTransfer & {
  partnerName: string;
};

function sortFundTransfers(fundTransfers: BaseFundTransfer[]): BaseFundTransfer[] {
  return fundTransfers.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));
}

export default function FundTransfersPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [fundTransfers, setFundTransfers] = useState<Record<string, BaseFundTransferWithPartnerName[]>>({});
  const [selectedTab, setSelectedTab] = useState<string>(ALL_TRANSFERS_TAB);
  const [filtersState, setFiltersState] = useState<FundTransfersFiltersState>({});
  const [displayedFundTransfers, setDisplayedFundTransfers] = useState<BaseFundTransferWithPartnerName[]>([]);

  const fundTransferTableTabs = getFundTransferTableTabs(fundTransfers);

  const fetchFundTransfers = useCallback(async () => {
    const fundTransfers = await FundTransfersService.getInstance().getFundTransfers();

    const fundTransfersGroupedByTab = getFundTransfersGroupedByTableTabs(
      sortFundTransfers(fundTransfers).map((fundTransfer) => ({
        ...fundTransfer,
        partnerName: getFundTransferPartnerName(fundTransfer)
      }))
    );

    setFundTransfers(fundTransfersGroupedByTab);

    return fundTransfersGroupedByTab;
  }, []);

  useEffect(() => {
    const initFundTransfers = async () => {
      const fundTransfers = await doWithLoader(fetchFundTransfers, setLoading);
      setDisplayedFundTransfers(fundTransfers[ALL_TRANSFERS_TAB]);
    };

    initFundTransfers();
  }, [fetchFundTransfers]);

  const handleTabSelected = (tab: string) => {
    setSelectedTab(tab);
    setFiltersState({});
    setDisplayedFundTransfers(fundTransfers[tab]);
  };

  const applyFilters = (fundTransfers: BaseFundTransferWithPartnerName[], filtersState: FundTransfersFiltersState | undefined) => {
    setDisplayedFundTransfers(
      fundTransfers.filter((fundTransfer) => {
        const { externalId, partnerName } = filtersState || {};

        if (externalId && fundTransfer.externalId !== externalId) {
          return false;
        }

        return !partnerName || partnerName === getFundTransferPartnerName(fundTransfer);
      })
    );
  };

  const persistCollectionFundTransfers = () =>
    doWithLoader(async () => {
      await FundTransfersService.getInstance().persistFundTransfers();
      notifySuccess('Fund transfer will be persisted shortly in the background.');
      const groupedFundTransfers = await fetchFundTransfers();
      applyFilters(groupedFundTransfers[selectedTab], filtersState);
    }, setLoading);

  const getTreasuryInboundSourceFundTransfers = (fundTransfer: BaseFundTransfer) => {
    const ft = fundTransfer as TreasuryInboundFundTransfer;
    return [...ft.sourceInternalOffsetOutboundTransfers];
  };

  const getTreasuryOutboundSourceFundTransfers = (fundTransfer: BaseFundTransfer) => {
    const ft = fundTransfer as TreasuryOutboundFundTransfer;
    return [...ft.sourceInternalOffsetInboundTransfers];
  };

  const expandableFundTransferData = (fundTransfer: BaseFundTransfer) => {
    switch (fundTransfer.type) {
      case FundTransferType.TreasuryInbound:
        return <FundTransfersTable noHeader fundTransfers={getTreasuryInboundSourceFundTransfers(fundTransfer)} />;
      case FundTransferType.TreasuryOutbound:
        return <FundTransfersTable noHeader fundTransfers={getTreasuryOutboundSourceFundTransfers(fundTransfer)} />;
      case FundTransferType.HedgingSpotOutbound:
        return (
          <FundTransfersTable noHeader fundTransfers={[(fundTransfer as HedgingSpotOutboundFundTransfer).hedgingSpotInboundFundTransfer]} />
        );
      default:
        return undefined;
    }
  };

  return (
    <Page className="fund-transfers-page-container" title="All Transfers" subtitle="View All Transfers by Partner">
      <CTAButton className="persist-button-container" text="Persist" onClick={persistCollectionFundTransfers} />
      <SectionCard
        title="All Transfers"
        icon={<ArrowCircleDown2 color={colors.almostBlack} />}
        iconBackgroundColor={colors.secondaryYellow}
        loading={loading}
      >
        <BaseTabs
          tabs={fundTransferTableTabs.map((f) => ({ value: f, title: f }))}
          selectedValue={selectedTab}
          onValueSelected={handleTabSelected}
        />
        <ManagedFiltersForm
          filtersData={filtersState}
          setFiltersData={setFiltersState}
          onFilterClicked={(_, filters) => applyFilters(fundTransfers[selectedTab], filters)}
          options={fundTransfers[selectedTab]}
          filters={[
            { label: 'External ID', key: 'externalId' },
            { label: 'Partner Name', key: 'partnerName' }
          ]}
        />
        <FundTransfersTable
          fundTransfers={displayedFundTransfers}
          paginated
          pageSize={10}
          renderSubComponent={expandableFundTransferData}
          selectedTab={selectedTab}
        />
      </SectionCard>
    </Page>
  );
}

async function doWithLoader<T extends () => Promise<any>>(
  cb: T,
  loaderSetterFn: React.Dispatch<React.SetStateAction<boolean>>
): Promise<Awaited<ReturnType<T>>> {
  try {
    loaderSetterFn(true);
    return await cb();
  } finally {
    loaderSetterFn(false);
  }
}
