import { AdminConsoleApiAuditLog } from '@grain/audit-logs-service-types';
import BaseTable, { BaseTableColumnDef } from '@grain/web-components/table/BaseTable';
import React from 'react';
import { sortByDates } from '@grain/web-components/table/sorting';
import { asDateString } from '@grain/web-components/utils/formatting';

type AuditLogsTableProps = {
  auditLogs: AdminConsoleApiAuditLog[];
  loading?: boolean;
};

export default function AuditLogsTable({ auditLogs, loading }: AuditLogsTableProps) {
  const columns: BaseTableColumnDef<AdminConsoleApiAuditLog>[] = [
    {
      accessorFn: (auditLog) => asDateString(auditLog.timestamp, true),
      id: 'auditTime',
      header: 'Audit Time',
      sortingFn: sortByDates
    },
    {
      accessorFn: (auditLog) => auditLog.actorId,
      id: 'actorId',
      header: 'Actor Id'
    },
    {
      accessorFn: (auditLog) => auditLog.actorType,
      id: 'actorType',
      header: 'Actor Type'
    },
    {
      accessorFn: (auditLog) => auditLog.organizationName,
      id: 'organization',
      header: 'organization'
    },
    {
      accessorFn: (auditLog) => auditLog.action,
      id: 'action',
      header: 'Action'
    },
    {
      accessorFn: (auditLog) => auditLog.targetId,
      id: 'targetId',
      header: 'Target Id'
    },
    {
      accessorFn: (auditLog) => auditLog.targetType,
      id: 'targetType',
      header: 'Target Type'
    },
    {
      accessorFn: (auditLog) => auditLog.description,
      id: 'description',
      header: 'Description'
    },
    {
      cell: React.useCallback(
        // @ts-expect-error - moving to React 18
        ({ row: { original: auditLog } }) => (
          <pre>{Object.keys(auditLog.metadata).length !== 0 && JSON.stringify(auditLog.metadata, null, 2)}</pre>
        ),
        []
      ),
      id: 'metadata',
      header: 'Metadata'
    }
  ];

  return (
    <div className="audit-logs-table-container">
      <BaseTable columns={columns} data={auditLogs} paginated fixedLayout loading={loading} />
    </div>
  );
}
