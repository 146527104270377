import { DashboardRole } from '@grain/core-types';

const DashboardRoleNamesMapping = {
  [DashboardRole.PartnerOwner]: 'Admin',
  [DashboardRole.PartnerDeveloper]: 'Developer',
  [DashboardRole.PartnerFinOps]: 'Financial Operations',
  [DashboardRole.PartnerAccounting]: 'Accounting',
  [DashboardRole.Default]: 'Default',
  [DashboardRole.GrainAdmin]: 'Grain Admin',
  [DashboardRole.Exploration]: 'Exploration'
};

export const DashboardRoleNames = Object.entries(DashboardRoleNamesMapping).map(([value, text]) => ({ value, text }));
export function getDashboardRoleNameByRole(role: DashboardRole) {
  return DashboardRoleNamesMapping[role];
}
