import { BaseFundTransfer, FundTransferType } from '@grain/admin-console-api-types';
import { capitalizeFirstLetter } from '@grain/formatters';

export const ALL_TRANSFERS_TAB = 'All Transfers';

export function getFundTransfersGroupedByTableTabs<T extends BaseFundTransfer>(fundTransfers: T[]): Record<string, T[]> {
  return fundTransfers.reduce(
    (groups, fundTransfer) => {
      const tabText = mapFundTransferTypeToTabText(fundTransfer.type);

      return {
        ...groups,
        [tabText]: [...(groups[tabText] || []), fundTransfer],
        [ALL_TRANSFERS_TAB]: [...groups[ALL_TRANSFERS_TAB], fundTransfer]
      };
    },
    { [ALL_TRANSFERS_TAB]: [] } as Record<string, T[]>
  );
}

export function getFundTransferTableTabs(fundTransfers: Record<string, BaseFundTransfer[]>): string[] {
  return [
    ALL_TRANSFERS_TAB,
    ...Object.keys(fundTransfers)
      .filter((key) => key !== ALL_TRANSFERS_TAB)
      .sort()
  ];
}

function mapFundTransferTypeToTabText(type: FundTransferType): string {
  return capitalizeFirstLetter(type);
}

export function isTabSelected(selectedTab: string | undefined, type: FundTransferType): boolean {
  return selectedTab === mapFundTransferTypeToTabText(type);
}

export function isAllTransfersTabSelected(selectedTab: string | undefined): boolean {
  return selectedTab === ALL_TRANSFERS_TAB;
}
