import React, { ReactElement } from 'react';
import './BaseInput.scss';
import { cn } from '../utils';
import BaseField, { FieldElementProps } from './BaseField';
import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';

/**
 @deprecated
 Use Input instead
*/
export default function BaseInput(props: BaseInputProps) {
  const {
    variant = 'outlined',
    name,
    innerLabel,
    labelText,
    password,
    errorMessage,
    className,
    required,
    disabled,
    hideErrorText,
    shadow,
    iconProps,
    small,
    extraSmall,
    borderless,
    sideLabel,
    internalTextFieldParams,
    type,
    placeholder,
    ...inputOptions
  } = props;
  const fieldType = password ? 'password' : type;

  const { icon, clickable = false, position = 'leading' } = iconProps || {};

  return (
    <div
      className={cn('base-input-container', position, {
        'with-icon': iconProps
      })}
    >
      <BaseField
        name={name}
        labelText={innerLabel ? undefined : labelText}
        errorMessage={errorMessage}
        required={required}
        shadow={shadow}
        hideErrorText={hideErrorText}
        sideLabel={sideLabel}
      >
        <div className="base-input-inner-container">
          {position === 'leading' && <div className={cn('icon-wrapper', { clickable })}>{icon}</div>}
          <TextField
            placeholder={placeholder}
            variant={variant}
            type={fieldType}
            name={name}
            label={innerLabel ? labelText : undefined}
            required={required}
            disabled={disabled}
            InputProps={{ ...inputOptions }}
            className={cn('field-element base-input', className, {
              'extra-small': extraSmall,
              small,
              disabled,
              borderless,
              error: !!errorMessage,
              big: innerLabel
            })}
            {...internalTextFieldParams}
          />
          {position === 'trailing' && <div className={cn('icon-wrapper', { clickable })}>{icon}</div>}
        </div>
      </BaseField>
    </div>
  );
}

export interface BaseInputProps extends FieldElementProps {
  password?: boolean;
  iconProps?: InputIconProps;
  small?: boolean;
  extraSmall?: boolean;
  borderless?: boolean;
  internalTextFieldParams?: TextFieldProps;
  innerLabel?: boolean;
  variant?: 'standard' | 'filled' | 'outlined';
  className?: string;
}

export interface InputIconProps {
  icon: ReactElement;
  clickable?: boolean;
  position?: 'leading' | 'trailing';
}
