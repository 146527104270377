import React, { MouseEvent } from 'react';
import './BaseTabs.scss';
import { cn } from '../utils';

export default function BaseTabs(props: BaseTabsProps) {
  const { className, tabs, selectedValue, onValueSelected } = props;

  const tabClicked = (e: MouseEvent<HTMLDivElement>) => {
    const { id } = e.currentTarget;
    onValueSelected?.(id);
  };

  return (
    <div className={cn('base-tabs-container', className)}>
      {tabs.map((t) => (
        <div
          key={t.value}
          id={t.value}
          className={cn('tab', {
            selected: t.value === selectedValue,
            disabled: t.disabled
          })}
          onClick={tabClicked}
        >
          {t.title}
        </div>
      ))}
    </div>
  );
}

export type Tab = {
  value: string;
  title: string;
  disabled?: boolean;
};

type BaseTabsProps = {
  tabs: Tab[];
  selectedValue: string;
  onValueSelected?: (selectedValue: string) => void;
  className?: string;
};
