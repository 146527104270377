import { useMutation, useQueryClient } from '@tanstack/react-query';
import PartnersService, { type CreateWalletParams } from 'services/PartnersService';
import { queries } from '../../../queries';

export const useCreateWallet = () => {
  const queryClient = useQueryClient();

  const {
    mutateAsync: createWallet,
    isError: walletCreationFailed,
    isPending: isCreatingWallet,
    isSuccess: walletCreationSucceeded,
    reset: resetCreateWalletMutation,
    error: walletCreationError
  } = useMutation<void, Error, CreateWalletParams>({
    mutationFn: (params) => PartnersService.getInstance().createWallet(params),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queries.partners.wallets.list._def });
    }
  });

  return {
    createWallet,
    walletCreationFailed,
    isCreatingWallet,
    walletCreationSucceeded,
    resetCreateWalletMutation,
    walletCreationError
  };
};
