import { useMutation, useQueryClient } from '@tanstack/react-query';
import PartnersService, { UpdatePartnerConfigParams } from 'services/PartnersService';
import { queries } from '../../../queries';

export const useUpdatePartnerConfig = () => {
  const queryClient = useQueryClient();

  const { mutateAsync, isError, isPending, isSuccess, reset, error } = useMutation<void, Error, UpdatePartnerConfigParams>({
    mutationFn: (params) => PartnersService.getInstance().updatePartnerConfig(params),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: queries.partner.detail._def });
    }
  });

  return {
    updatePartnerConfig: mutateAsync,
    partnerConfigUpdateFailed: isError,
    isUpdatingPartnerConfig: isPending,
    partnerConfigUpdateSucceeded: isSuccess,
    resetUpdatePartnerConfigMutation: reset,
    partnerConfigUpdateError: error
  };
};
