import { useMutation, useQueryClient } from '@tanstack/react-query';
import PartnersService, { DeleteCurrencyPairParams } from 'services/PartnersService';
import { notifySuccess } from '@grain/web-components/notifications/toasters';
import { queries } from '../../../../queries';

export const useDeleteCurrencyPair = () => {
  const queryClient = useQueryClient();
  const { error, isError, isPending, isSuccess, mutateAsync } = useMutation<void, Error, DeleteCurrencyPairParams>({
    mutationFn: (params) => PartnersService.getInstance().deleteCurrencyPair(params),
    onSuccess: async () => {
      notifySuccess('Currency pair deleted successfully');
      await Promise.all([
        queryClient.refetchQueries({ queryKey: queries.partners.currencyPairs._def }),
        queryClient.refetchQueries({ queryKey: queries.partner.detail._def })
      ]);
    }
  });

  return {
    deletedCurrencyPairSuccessfully: isSuccess,
    failedToDeleteCurrencyPair: isError,
    deleteCurrencyPair: mutateAsync,
    isDeletingCurrencyPair: isPending,
    deleteCurrencyPairError: error
  };
};
