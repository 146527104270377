import { PartnerWalletsTable } from './PartnerWalletsTable';
import { Card } from '@grain/web-components/layout/Card';
import { IconicButton } from '@grain/web-components/iconic-button/IconicButton';
import { Add, SearchStatus1, ToggleOffCircle } from 'iconsax-react';
import React from 'react';
import { SeparatorRow } from '@grain/web-components/separator-row/SeparatorRow';
import PaymentBeneficiariesService from '../../../services/PaymentBeneficiaries';
import { useQueryClient } from '@tanstack/react-query';
import { queries } from '../../../queries';
import SelectOptionDialog from '@grain/web-components/dialogs/SelectOptionDialog';
import { TRANSFER_MATE_SUPPORTED_CURRENCIES } from '@grain/rate-utils';
import { useCreateWallet } from '../hooks';
import { AdminConsoleApiPartner } from '@grain/admin-console-api-types';
import { TreasuryService } from '../../../services/TreasuryService';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  AccordionTriggerActions
} from '@grain/web-components/accordion/Accordion';
import { PartnerSectionTitleMarker } from '../partner/PartnerSectionTitleMarker';
import { getOrganizationTypeText, useOrganizationType } from '../../organizations/getOrganizationType';

export const PartnerWalletsSection = (props: PartnerWalletsSectionProps) => {
  const queryClient = useQueryClient();
  const [organizationType, toggleOrganizationType] = useOrganizationType();

  const { createWallet } = useCreateWallet();

  const syncBeneficiaries = async () => {
    try {
      await PaymentBeneficiariesService.getInstance().syncPaymentBeneficiaries({
        partnerId: props.partner.id
      });
      await queryClient.refetchQueries({ queryKey: queries.partners.wallets.list._def });
    } catch (error) {
      console.error(error);
    }
  };

  const createTreasuryBeneficiaries = async () => {
    try {
      await TreasuryService.getInstance().addPartnerBeneficiariesToTreasury(props.partner.id);
      await queryClient.refetchQueries({ queryKey: queries.partners.wallets.list._def });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card>
      <Accordion type="single" collapsible>
        <AccordionItem value="item-1">
          <AccordionTrigger>
            <span className="font-medium text-secondary-grey flex items-center gap-2">
              <PartnerSectionTitleMarker color="secondary-pink" />
              Wallets
            </span>

            <AccordionTriggerActions>
              <SeparatorRow>
                <IconicButton icon={SearchStatus1} text="Sync Beneficiaries" onClick={syncBeneficiaries} />

                <IconicButton icon={Add} text="Create treasury beneficiaries" onClick={createTreasuryBeneficiaries} />

                <IconicButton
                  icon={ToggleOffCircle}
                  text={`${getOrganizationTypeText(organizationType)} Wallets`}
                  onClick={toggleOrganizationType}
                />

                <SelectOptionDialog
                  title="Add Wallet"
                  initialOption=""
                  options={TRANSFER_MATE_SUPPORTED_CURRENCIES.map((currency) => ({
                    text: currency,
                    value: currency
                  }))}
                  onSubmit={(currency) =>
                    createWallet({
                      partnerId: props.partner.id,
                      currency
                    })
                  }
                  trigger={<IconicButton icon={Add} text="Add Wallet" />}
                />
              </SeparatorRow>
            </AccordionTriggerActions>
          </AccordionTrigger>

          <AccordionContent className="h-96">
            <PartnerWalletsTable partner={props.partner} organizationType={organizationType} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </Card>
  );
};

export type PartnerWalletsSectionProps = {
  partner: AdminConsoleApiPartner;
};
