import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notifySuccess } from '@grain/web-components/notifications/toasters';
import GrainSettingsService from '../../../services/GrainSettingsService';
import { queries } from '../../../queries';

export const useDeleteGrainCurrencyPair = () => {
  const queryClient = useQueryClient();
  const { error, isError, isPending, isSuccess, mutateAsync } = useMutation<void, Error, { id: string }>({
    mutationFn: ({ id }) => GrainSettingsService.getInstance().removeGrainCurrencyPair(id),
    onSuccess: async () => {
      notifySuccess('Grain currency pair deleted successfully');
      await queryClient.refetchQueries({ queryKey: queries.settings.grainCurrencyPairs.all._def });
    }
  });

  return {
    isSuccessDeleteGrainCurrencyPair: isSuccess,
    isErrorDeleteGrainCurrencyPair: isError,
    deleteGrainCurrencyPairAsync: mutateAsync,
    isLoadingDeleteGrainCurrencyPair: isPending,
    errorDeleteGrainCurrencyPair: error
  };
};
